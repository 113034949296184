export default {
  ANALISE: {
    EM_ANALISE: 'EM ANÁLISE',
    EM_ANALISE_AVALIACAO: 'EM ANÁLISE AVALIAÇÃO',
    EM_ANALISE_PENDENTE: 'EM ANÁLISE PENDENTE',
    EM_ANALISE_PERICIA: 'EM ANÁLISE PERÍCIA',
    EXIGENCIA_AVALIACAO: 'EXIGÊNCIA AVALIAÇÃO',
    EXIGENCIA_PERICIA: 'EXIGÊNCIA PERÍCIA',

  },
  INTERACAO: {
    AGENDADO: 'AGENDADO',
    AGUARDANDO: 'AGUARDANDO',
    ATENDIMENTO_PENDENTE: 'ATENDIMENTO PENDENTE',
    ATENDIDO_NO_PRESENCIAL: 'ATENDIDO NO PRESENCIAL',
    CHAMOU: 'CHAMOU',
    DOMICILIO: 'DOMICÍLIO',
    EM_ATENDIMENTO: 'EM ATENDIMENTO',
    NAO_COMPARECEU: 'NÃO COMPARECEU',
    NAO_QUER_ASSINAR: 'NÃO QUER ASSINAR',
    NAO_RESPONDE: 'NÃO RESPONDE',
    NAO_TEM_DIREITO: 'NÃO TEM DIREITO',
    REAGENDADO: 'REAGENDADO',
    SEM_INTERESSE: 'SEM INTERESSE',
    VIRGEM: 'VIRGEM',
  },
  DECISAO: {
    DEFERIDO_CANCELADO: 'DEFERIDO/CANCELADO',
    DEFERIDO: 'DEFERIDO',
    EXIGENCIA: 'EXIGÊNCIA',
    IDEFERIDO: 'INDEFERIDO',
    RECURSO: 'RECURSO',
  },
  OUTROS: {
    ACOMPANHANTE: 'ACOMPANHANTE',
    FA: 'FÃ',
    FALECEU: 'FALECEU',
    INFORMACAO: 'INFORMAÇÃO',
    NAO_TEM_IDADE: 'NÃO TEM IDADE',
    NAO_TEM_LAUDO: 'NÃO TEM LAUDO',
    NAO_TEM_TEMPO_DE_CONTRIBUICAO: 'NÃO TEM TEMPO DE CONTRIBUIÇÃO',
    NOVAS_MENSAGENS: 'NOVAS MENSAGENS',
    OUTROS: 'OUTROS',
    POTENCIAL_CLIENTE: 'POTENCIAL CLIENTE',
    RETORNAR: 'RETORNAR',
    SPAM: 'SPAM',
    VAI_RETORNAR: 'VAI RETORNAR',
    DESMARCOU: 'DESMARCOU',
  },
  PROCESSO: {
    CANCELADO: 'CANCELADO',
    ENGANO: 'ENGANO',
    ENTRADA: 'ENTRADA',
    RESPONDIDO: 'RESPONDIDO',
    VAI_PENSAR: 'VAI PENSAR',
  },
};
