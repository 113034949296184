export default {
  CANCELADO: 'CANCELADO',
  ENGANO: 'ENGANO',
  RESPONDIDO: 'RESPONDIDO',
  VAI_PENSAR: 'VAI PENSAR',
  EM_ANALISE: 'EM ANÁLISE',
  EM_ANALISE_AVALIACAO: 'EM ANÁLISE AVALIAÇÃO',
  EM_ANALISE_PENDENTE: 'EM ANÁLISE PENDENTE',
  EM_ANALISE_PERICIA: 'EM ANÁLISE PERÍCIA',
  EXIGENCIA_PERICIA: 'EXIGÊNCIA PERÍCIA',
  EXIGENCIA_AVALIACAO: 'EXIGÊNCIA AVALIAÇÃO',

  DEFERIDO: 'DEFERIDO',
  IDEFERIDO: 'INDEFERIDO',
  DEFERIDO_CANCELADO: 'DEFERIDO/CANCELADO',
  EXIGENCIA: 'EXIGÊNCIA',
  RECURSO: 'RECURSO',
  AGUARDANDO: 'AGUARDANDO',
  CHAMOU: 'CHAMOU',
  EM_ATENDIMENTO: 'EM ATENDIMENTO',
  NAO_QUER_ASSINAR: 'NÃO QUER ASSINAR',
  NAO_RESPONDE: 'NÃO RESPONDE',
  NAO_TEM_DIREITO: 'NÃO TEM DIREITO',
  NAO_COMPARECEU: 'NÃO COMPARECEU',
  SEM_INTERESSE: 'SEM INTERESSE',
  DOMICILIO: 'DOMICÍLIO',
  VIRGEM: 'VIRGEM',
  RETORNAR: 'RETORNAR',
  INFORMACAO: 'INFORMAÇÃO',
  POTENCIAL_CLIENTE: 'POTENCIAL CLIENTE',
  NOVAS_MENSAGENS: 'NOVAS MENSAGENS',
  ATENDIMENTO_PENDENTE: 'ATENDIMENTO PENDENTE',
  ATENDIDO_NO_PRESENCIAL: 'ATENDIDO NO PRESENCIAL',
  AGENDADO: 'AGENDADO',
  REAGENDADO: 'REAGENDADO',
  ACOMPANHANTE: 'ACOMPANHANTE',
  FA: 'FÃ',
  SPAM: 'SPAM',
  NAO_TEM_IDADE: 'NÃO TEM IDADE',
  NAO_TEM_LAUDO: 'NÃO TEM LAUDO',
  NAO_TEM_TEMPO_DE_CONTRIBUICAO: 'NÃO TEM TEMPO DE CONTRIBUIÇÃO',
  VAI_RETORNAR: 'VAI RETORNAR',
  OUTROS: 'OUTROS',
  FALECEU: 'FALECEU',
  ENTRADA: 'ENTRADA',
};
