import React, { createContext, useEffect, useState } from 'react';

import api from '../config/configApi';

const Context = createContext();

function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [empresaIdContext, setEmpresaIdContext] = useState('');
  const [equipeIdContext, setEquipeaIdContext] = useState('');
  const [matricula, setMatricula] = useState('');
  const [perfil, setPerfil] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getLogin = async () => {
      const token = localStorage.getItem('token');
      const empresaDoUsuario = localStorage.getItem('empresaId');
      const equipeDoUsuario = localStorage.getItem('equipeId');
      const matriculaDoUsuario = localStorage.getItem('matricula');
      const perfilDoUsuario = localStorage.getItem('perfilId');

      if (token && valUser()) {
        api.defaults.headers.Authorization = `Bearer ${(token)}`;
        setAuthenticated(true);
        setEmpresaIdContext(empresaDoUsuario);
        setEquipeaIdContext(equipeDoUsuario);
        setMatricula(matriculaDoUsuario);
        setMatricula(perfilDoUsuario);
        setPerfil(perfilDoUsuario);
      }

      setLoading(false);
    };

    getLogin();
  }, []);

  const valUser = async () => {
    const valueToken = localStorage.getItem('token');

    const headers = {
      headers: {
        Authorization: `Bearer ${valueToken}`,
      },
    };

    await api.get('/login/val-token', headers)
      .then(() => true).catch(() => {
        setAuthenticated(false);
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        localStorage.removeItem('image');
        localStorage.removeItem('empresaId');
        localStorage.removeItem('equipeId');
        localStorage.removeItem('matricula');
        localStorage.removeItem('perfil');
        api.defaults.headers.Authorization = undefined;
        setEmpresaIdContext(null);
        setEquipeaIdContext(null);
        setMatricula(null);
        localStorage.clear();
        return false;
      });
  };

  async function signIn(sit) {
    setAuthenticated(true);
  }

  function handleLogout() {
    setAuthenticated(false);
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('image');
    localStorage.removeItem('empresaId');
    localStorage.removeItem('equipeId');
    localStorage.removeItem('matricula');
    localStorage.removeItem('perfil');
    api.defaults.headers.Authorization = undefined;
    setEmpresaIdContext(null);
    setEquipeaIdContext(null);
    setMatricula(null);
    localStorage.clear();
  }

  if (loading) {
    return <h1>Carregando...</h1>;
  }

  return (
    <Context.Provider value={{
      authenticated, empresaIdContext, equipeIdContext, matricula, perfil, signIn, handleLogout,
    }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, AuthProvider };
