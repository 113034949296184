import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavBar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Context } from '../../Context/AuthContext';

const perfilMaster = '99';

export function Navbar() {
  const [image] = useState(localStorage.getItem('urlfoto'));
  const [name] = useState(localStorage.getItem('name'));

  const { handleLogout, perfil } = useContext(Context);

  const dropdownUserNavbar = async () => {
    document.getElementById('dropNavbarUser').classList.toggle('dropdown-menu-action');
  };

  const barsSidebar = async () => {
    document.getElementById('barsSidebar').classList.toggle('sidebar-active');
  };

  const [style, setStyle] = useState('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion');

  const changeStyle = () => {
    if (style == 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion') {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled');
    } else {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion');
    }
  };
  const changeStyle1 = () => {
    if (style == 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion') {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1');
    } else {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion');
    }
  };

  return (
    <NavBar collapseOnSelect expand="lg" className="navbar navbar-light bg-white mb-4 shadow">
      <Container>
        <NavBar.Toggle aria-controls="responsive-navbar-nav" />
        <NavBar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto align-items-lg-center">

            { perfilMaster === localStorage.getItem('perfilId') ? (<Nav.Link href="/admin/relatorios">Relatórios</Nav.Link>) : null }
            <Nav.Link href="/atendimentos-presenciais">Atendimentos</Nav.Link>
            { perfilMaster === localStorage.getItem('perfilId') ? (<Nav.Link href="/users">Usuários</Nav.Link>) : null }
            <Nav.Link href="/chamados">Chamados TI</Nav.Link>
            <Nav.Link href="/pessoas">Pessoas</Nav.Link>
            <Nav.Item className="nav-item dropdown no-arrow">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="userDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                  {localStorage.getItem('name')}
                  {' '}
                </span>
                <img
                  className="img-profile rounded-circle"
                  width="30"
                  src="img/undraw_profile.svg"
                />
              </a>
              {/*  <!-- Dropdown - User Information --> */}
              <div
                className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="userDropdown"
              >
                <div className="dropdown-item">
                  <Link to="/view-profile" className="sidebar-nav">
                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                    <span>Perfil</span>
                  </Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/" onClick={handleLogout} className="sidebar-nav">
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                    <span>Sair</span>
                  </Link>
                </div>
              </div>
            </Nav.Item>
            <Nav.Link href="/ajuda"><i className="fa fa-question-circle" aria-hidden="true" /></Nav.Link>
          </Nav>
        </NavBar.Collapse>
      </Container>
    </NavBar>
  );
}
