import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';
import api from '../../../config/configApi';
import EmptyState from '../../Shared/EmptyState';

export default function Contatos() {
  const [contacts, setContacts] = useState([]);
  const [spinnerSearch, setSpinnerSearch] = useState();

  const getContacts = async () => {
    setSpinnerSearch(true);
    try {
      const response = await api.get('/contatos');
      setContacts(response.data?.contatos);
    } catch (error) {
      alert('Erro ao buscar os contatos.');
    }
    setSpinnerSearch(false);
  };

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <>
      <div
        id="wrapper"
        style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      >
        <Sidebar />
        <div id="content-wrapper" style={{ flex: '1', overflow: 'hidden' }}>
          <div id="content" style={{ height: '100vh', overflowY: 'auto' }}>
            <Navbar />
            <div className="container-fluid">
              {/*  <!-- Page Heading --> */}
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1> Contatos </h1>
              </div>

              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <div className="row align-items-center">
                    <div className="col-lg-9 col-md-12">
                      <div className="row align-items-center"></div>
                    </div>
                    <div className="col-lg-3 col-md-5">
                      <div className="row align-items-center"></div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <div className="card-body">
                    {spinnerSearch ? (
                      <div className="d-flex justify-content-center">
                        <Spinner
                          as="span"
                          animat
                          ion="border"
                          size="lg"
                          role="status"
                          aria-hidden="true"
                        >
                          <span className="sr-only">Buscando...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <>
                        <table className="table table-sm">
                          <thead>
                            <tr>
                              <th hidden>ID</th>
                              <th>Nome</th>
                              <th>Apelido</th>
                              <th>Telefone</th>
                              <th>E-mail</th>
                              <th>Tags</th>
                              <th>Observações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {contacts.map((contact) => (
                              <tr key={contact.id}>
                                <td>{contact.name}</td>
                                <td>{contact.nickName}</td>
                                <td>{contact.number}</td>
                                <td>{contact.email}</td>
                                <td>
                                  {contact.tags?.map((tag, index) => (
                                    <>
                                      <span className="badge badge-secondary m-1 text-uppercase">
                                        {tag.Description}
                                      </span>
                                      { contact.tags.length === (index + 1) ? '' : ', '}
                                    </>
                                  ))}
                                </td>
                                <td>{contact.observation}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <EmptyState isActive={!contacts.length} />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
