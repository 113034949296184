import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import './App.css';
import { Provider } from 'react-redux';
import Routes from './routes/routesAdm';

import history from './services/history';
import { AuthProvider } from './Context/AuthContext';

import Store from './store/configureStore';
import Footer from './components/Footer/Footer';

function App() {
  const [style, setStyle] = useState('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion');

  const changeStyle = () => {
    if (style === 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion') {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled');
    } else {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion');
    }
  };
  const changeStyle1 = () => {
    if (style === 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion') {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1');
    } else {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion');
    }
  };
  return (
    <div>
      <AuthProvider>
        <Provider store={Store}>
          <Router history={history}>
            <main className="d-flex flex-column">
              <Routes />
              <Footer />
            </main>
          </Router>
        </Provider>
      </AuthProvider>
    </div>
  );
}

export default App;
