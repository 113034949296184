export const validateCPF = (cpf) => {
  if (!cpf) return true;
  // Remove any non-digit characters from the CPF
  const cleanedCPF = cpf.replace(/\D/g, '');

  // Validate the CPF format
  const cpfRegex = /^\d{11}$/;
  if (!cpfRegex.test(cleanedCPF)) {
    return false;
  }

  // Validate the CPF checksum
  let sum = 0;
  let remainder;
  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(cleanedCPF.substring(i - 1, i), 10) * (11 - i);
  }
  remainder = (sum * 10) % 11;

  if ((remainder === 10) || (remainder === 11)) {
    remainder = 0;
  }

  if (remainder !== parseInt(cleanedCPF.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(cleanedCPF.substring(i - 1, i), 10) * (12 - i);
  }
  remainder = (sum * 10) % 11;

  if ((remainder === 10) || (remainder === 11)) {
    remainder = 0;
  }

  if (remainder !== parseInt(cleanedCPF.substring(10, 11), 10)) {
    return false;
  }

  return true;
};

export const cpfMask = (value) => value
  .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
  .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1');
