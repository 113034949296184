import { combineReducers, configureStore } from '@reduxjs/toolkit';
import isButtonEnabled from './isButtonEnabled';

const reducer = combineReducers({
  isButtonEnabled,
});

const store = configureStore({ reducer });

export default store;
