import { previdenciaSeguroSocialImg } from './previdenciaSeguroSocial';

const estadosCivis = {
  1: 'Solteiro',
  2: 'Casado',
  3: 'Separado',
  4: 'Divorciado',
  5: 'Viúvo',
};

const procuracaoContent = ({
  nome,
  bairro,
  cidade,
  rg,
  cpf,
  logradouro,
  numero,
  complemento,
  cep,
  estadoCivil,
  isEstrangeiro,
  estado,
// eslint-disable-next-line arrow-body-style
}) => {
  return {
    content: [
      {
        image: previdenciaSeguroSocialImg,
        width: 140,
        alignment: 'center',
      },
      {
        text: 'ANEXO IV',
        alignment: 'center',
      },
      {
        text: 'INSTRUÇÃO NORMATIVA No 77 /PRES/INSS, DE 21 DE JANEIRO DE 2015 PROCURAÇÃO\n\n',
        alignment: 'center',
      },
      {
        table: {
          widths: ['*'],
          body: [[{ text: 'ESPÉCIE/NB:', alignment: 'center' }]],
        },
      },
      { text: '\n' },
      {
        stack: [
          { text: `${nome}\n` },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 10,
                x2: 595 - 2 * 40,
                y2: 10,
                lineWidth: 1,
              },
            ],
          },
          { text: 'NOME COMPLETO DO SEGURADO/PENSIONISTA' },
        ],
        lineHeight: 0.3,
      },
      { text: '\n' },
      {
        alignment: 'center',
        columns: [
          {
            stack: [
              {
                text: `${isEstrangeiro === '0' ? 'BRASILEIRA' : ''}\n`,
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 10,
                    x2: 595 - 2 * 230,
                    y2: 10,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'NACIONALIDADE',
              },
            ],
            lineHeight: 0.3,
          },
          {
            stack: [
              {
                text: `${estadosCivis[estadoCivil]}\n`,
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 10,
                    x2: 595 - 2 * 230,
                    y2: 10,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'ESTADO CIVIL',
              },
            ],
            lineHeight: 0.3,
          },
          {
            stack: [
              {
                text: `${rg}\n`,
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 10,
                    x2: 595 - 2 * 230,
                    y2: 10,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'IDENTIDADE',
              },
            ],
            lineHeight: 0.3,
          },
        ],
      },
      { text: '\n' },
      {
        alignment: 'justify',
        columns: [
          {
            fontSize: 7,
            width: 100,
            stack: [
              {
                text: `${cpf}\n`,
                alignment: 'center',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 250,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'CPF',
                alignment: 'center',
              },
            ],
          },
          {
            fontSize: 7,
            width: 135,
            stack: [
              {
                text: '\n',
                alignment: 'center',
                fontSize: 7,
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 235,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'PROFISSÃO',
                alignment: 'center',
                fontSize: 7,
              },
            ],
          },
          { width: 60, text: 'Reside Na', alignment: 'center' },
          {
            fontSize: 7,
            stack: [
              {
                text: logradouro,
                alignment: 'center',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 180,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'RUA/AVENIDA/PRAÇA',
                alignment: 'center',
              },
            ],
          },
        ],
      },
      { text: '\n' },
      {
        alignment: 'justify',
        columns: [
          {
            fontSize: 7,
            width: 60,
            stack: [
              {
                text: `${numero}\n`,
                alignment: 'center',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 270,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'Nº',
                alignment: 'center',
              },
            ],
          },
          {
            fontSize: 7,
            width: 100,
            stack: [
              {
                text: complemento || '\n',
                alignment: 'center',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 250,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'COMPLEMENTO',
                alignment: 'center',
              },
            ],
          },
          {
            fontSize: 7,
            width: 150,
            stack: [
              {
                text: `${bairro}\n`,
                alignment: 'center',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 230,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'BAIRRO',
                alignment: 'center',
              },
            ],
          },
          {
            fontSize: 7,
            width: 200,
            stack: [
              {
                text: [cidade || ' '.repeat('20'), estado || ' '.repeat(20), cep || ' '.repeat(20)].join(' / '),
                alignment: 'center',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 200,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'CIDADE/ESTADO/CEP',
                alignment: 'center',
              },
            ],
          },
        ],
      },
      {
        alignment: 'justify',
        columns: [
          {
            fontSize: 7,
            text: '\nnomeia e constitui seu bastante procurador o(a) Sr Sr(a).',
          },
          {
            alignment: 'justify',
            columns: [
              {
                fontSize: 7,
                width: 200,
                stack: [
                  {
                    text: '\n',
                    alignment: 'center',
                  },
                  {
                    canvas: [
                      {
                        type: 'line',
                        x1: 0,
                        y1: 5,
                        x2: 595 - 2 * 200,
                        y2: 5,
                        lineWidth: 1,
                      },
                    ],
                  },
                  {
                    text: 'NOME COMPLETO DO PROCURADOR',
                    alignment: 'center',
                  },
                ],
              },
            ],
          },
        ],
      },
      { text: '\n' },
      {
        alignment: 'center',
        columns: [
          {
            stack: [
              {
                text: '\n',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 10,
                    x2: 595 - 2 * 230,
                    y2: 10,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'NACIONALIDADE',
              },
            ],
            lineHeight: 0.3,
          },
          {
            stack: [
              {
                text: '\n',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 10,
                    x2: 595 - 2 * 230,
                    y2: 10,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'ESTADO CIVIL',
              },
            ],
            lineHeight: 0.3,
          },
          {
            stack: [
              {
                text: '\n',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 10,
                    x2: 595 - 2 * 230,
                    y2: 10,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'IDENTIDADE',
              },
            ],
            lineHeight: 0.3,
          },
        ],
      },
      { text: '\n' },
      {
        alignment: 'justify',
        columns: [
          {
            fontSize: 7,
            width: 100,
            stack: [
              {
                text: '\n',
                alignment: 'center',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 250,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'CPF',
                alignment: 'center',
              },
            ],
          },
          {
            fontSize: 7,
            width: 135,
            stack: [
              {
                text: '\n',
                alignment: 'center',
                fontSize: 7,
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 235,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'PROFISSÃO',
                alignment: 'center',
                fontSize: 7,
              },
            ],
          },
          { width: 60, text: 'Reside Na', alignment: 'center' },
          {
            fontSize: 7,
            stack: [
              {
                text: '\n',
                alignment: 'center',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 180,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'RUA/AVENIDA/PRAÇA',
                alignment: 'center',
              },
            ],
          },
        ],
      },
      { text: '\n' },
      {
        alignment: 'justify',
        columns: [
          {
            fontSize: 7,
            width: 60,
            stack: [
              {
                text: '\n',
                alignment: 'center',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 270,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'Nº',
                alignment: 'center',
              },
            ],
          },
          {
            fontSize: 7,
            width: 100,
            stack: [
              {
                text: '\n',
                alignment: 'center',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 250,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'COMPLEMENTO',
                alignment: 'center',
              },
            ],
          },
          {
            fontSize: 7,
            width: 150,
            stack: [
              {
                text: '\n',
                alignment: 'center',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 230,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'BAIRRO',
                alignment: 'center',
              },
            ],
          },
          {
            fontSize: 7,
            width: 200,
            stack: [
              {
                text: [' '.repeat(20), ' '.repeat(20), ' '.repeat(20)].join(' / '),
                alignment: 'center',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 200,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'CIDADE/ESTADO/CEP',
                alignment: 'center',
              },
            ],
          },
        ],
      },
      {
        text: '\n\na quem confere poderes especiais para representá-lo perante o INSS, bem como usar de todos os meios legais para o fiel cumprimento do presente mandato, por encontrar-se: \nINDICAR UMA DAS OPÇÕES ABAIXO\n\n',
      },
      {
        text: '(    )  Incapacitado de locomover-se ou portador de moléstia contagiosa,',
        margin: [20, 2],
      },
      {
        text: '(    )  Ausente (viagem dentro país ou exterior) período ______________',
        margin: [20, 2],
      },
      {
        text: '(    )  Residência no exterior (indicar o país ________________)',
        margin: [20, 2],
      },
      {
        text: 'com fins específicos de:\n',
      },
      {
        text: '    INDICAR UMA DAS OPÇÕES ABAIXO',
        margin: [7, 2],
      },
      {
        lineHeight: 0.5,
        text: [
          {
            text: '☐',
            color: 'green',
            fontSize: 30,
          },
          {
            text: 'Receber mensalidades de benefícios, receber quantias atrasadas e firmar os respectivos recibos.',
            fontSize: 10,
          },
        ],
      },
      {
        lineHeight: 0.5,
        text: [
          {
            text: '☐',
            color: 'green',
            fontSize: 30,
          },
          {
            text: 'Requerer benefícios, revisão e interpor recursos.',
            fontSize: 10,
          },
        ],
      },
      {
        lineHeight: 0.5,
        text: [
          {
            text: '☐',
            color: 'green',
            fontSize: 30,
          },
          {
            text: 'Comprovação de vida junto a rede bancária.',
            fontSize: 10,
          },
        ],
      },
      {
        lineHeight: 0.5,
        text: [
          {
            text: '☐',
            color: 'green',
            fontSize: 30,
          },
          {
            text: 'Cadastro de Senha para informações previdenciárias pela internet. Requerimentos diversos.',
            fontSize: 10,
          },
        ],
      },
      { text: '\n\n' },
      {
        alignment: 'justify',
        columns: [
          {
            width: 250,
            stack: [
              {
                text: 'Rio de Janeiro,',
                fontSize: 11,
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 200,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'LOCAL E DATA',
                alignment: 'center',
              },
            ],
          },
          {
            width: 250,
            stack: [
              {
                text: '\n',
                fontSize: 11,
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 170,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'ASSINATURA DO SEGURADO/PENSIONISTA',
                alignment: 'center',
              },
            ],
          },
        ],
      },
      { text: '\n' },
      {
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: [
                  { text: 'TERMO DE RESPONSABILIDADE\n', alignment: 'center' },
                  {
                    text: '      Pelo presente Termo de Responsabilidade, comprometo-me a comunicar ao INSS qualquer evento que possa anular a presente Procuração, no prazo de trinta dias, a contar da data que o mesmo ocorra, principalmente o óbito do segurado/pensionista, mediante apresentação da respectiva certidão. Estou ciente que o descumprimento do compromisso ora assumido, além de obrigar a devolução de importâncias\n',
                    alignment: 'justify',
                    preserveLeadingSpaces: true,
                  },
                  {
                    text: 'recebidas indevidamente, quando for o caso, sujeitar-me-á às penalidades previstas nos arts. 171 e 299, ambos do Código Penal.',
                    alignment: 'justify',
                  },
                ],
              },
            ],
          ],
        },
      },
      { text: '\n\n' },
      {
        alignment: 'justify',
        pageBreak: 'after',
        columns: [
          {
            width: 250,
            stack: [
              {
                text: 'Rio de Janeiro,',
                fontSize: 11,
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 200,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'LOCAL E DATA',
                alignment: 'center',
              },
            ],
          },
          {
            width: 250,
            stack: [
              {
                text: '\n',
                fontSize: 11,
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * 170,
                    y2: 5,
                    lineWidth: 1,
                  },
                ],
              },
              {
                text: 'ASSINATURA DO PROCURADOR',
                alignment: 'center',
              },
            ],
          },
        ],
      },
      {
        table: {
          widths: ['*'],
          body: [
            [
              {
                margin: [15, 5],
                text: [
                  { text: 'CÓDIGO PENAL\n', alignment: 'center' },
                  {
                    text: '      Art. 171. Obter, para si ou para outrem, vantagem ilícita, em prejuízo alheio, induzindo ou manter alguém em erro, mediante artifício, ardil ou qualquer outro meio fraudulento.\n',
                    alignment: 'justify',
                    preserveLeadingSpaces: true,
                  },
                  {
                    text: '      Art. 299. Omitir, em documento público ou particular, declaração que devia constar, ou nele inserir ou fazer inserir declaração falsa ou diversa da que devia ser escrita, com o fim de prejudicar direito, criar, obrigação ou alterar a verdade sobre fato juridicamente relevante.',
                    alignment: 'justify',
                    preserveLeadingSpaces: true,
                  },
                ],
              },
            ],
          ],
        },
        layout: {
          hLineColor(i, node) {
            return 'green';
          },
          vLineColor(i, node) {
            return 'green';
          },
        },
      },
    ],
    defaultStyle: {
      font: 'Arial',
      fontSize: 8,
    },
  };
};

// eslint-disable-next-line import/prefer-default-export
export { procuracaoContent };
