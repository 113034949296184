const SituacaoTooltip = {
  titulo: 'Situação:',
  descricao: 'O campo situação deverá ser preenchido de acordo com a linha do tempo de atendimento.',
  opcoes: {
    ANÁLISE: {
      EM_ANÁLISE: '',
      EM_ANÁLISE_AVALIAÇÃO: '',
      EM_ANÁLISE_PENDENTE: '',
      EM_ANÁLISE_PERÍCIA: '',
      EXIGÊNCIA_AVALIAÇÃO: '',
      EXIGÊNCIA_PERÍCIA: '',
    },
    INTERAÇÃO: {
      AGENDADO: 'Significa que o cliente passou pela triagem do 0800 e foi agendado para a unidade desejada.',
      AGUARDANDO: 'Significa que o cliente está aguardando para ser atendido presencialmente.',
      ATENDIMENTO_PENDENTE: '',
      ATENDIDO_NO_PRESENCIAL: 'Significa que o cliente foi atendido presencialmente nas unidades.',
      CHAMOU: 'Significa que tentamos contato com o cliente através de ligação e não obtemos sucesso.',
      DOMICÍLIO: 'Significa que o cliente foi agendado para atendimento presencial domiciliar.',
      EM_ATENDIMENTO: 'Significa que o cliente está em atendimento com a atendente.',
      NÃO_COMPARECEU: 'Significa que o cliente não compareceu à consulta.',
      NÃO_QUER_ASSINAR: 'Significa que o cliente não quis assinar o processo por algum motivo.',
      NÃO_RESPONDE: 'Significa que o cliente iniciou uma conversa pelo WhatsApp e parou de nos responder.',
      NÃO_TEM_DIREITO: 'Significa que por algum motivo o cliente não possui direito ao benefício/aposentadoria.',
      REAGENDADO: 'Significa que o cliente não pôde comparecer à data agendada e remarcou sua consulta.',
      SEM_INTERESSE: 'Significa que o cliente não tem mais interesse em nossa Assessoria.',
      VIRGEM: '',
    },
    DECISÃO: {
      DEFERIDO_CANCELADO: '',
      DEFERIDO: '',
      EXIGÊNCIA: '',
      IDEFERIDO: '',
      RECURSO: '',
    },
    OUTROS: {
      ACOMPANHANTE: 'Significa que a pessoa veio acompanhar o cliente que deseja consulta conosco.',
      FÃ: 'Pessoa que entra em contato para demonstrar carinho pela Assessoria ou pelo Marcelo Manhães.',
      FALECEU: 'Significa que o cliente veio a falecer.',
      INFORMAÇÃO: 'Significa que o cliente teve sua dúvida sanada.',
      NAO_TEM_IDADE: 'Usa-se essa situação quando o cliente ainda não tem idade para o benefício que solicitou.',
      NAO_TEM_LAUDO: 'Usa-se essa situação quando o cliente não tem laudo que comprove a incapacidade laborativa.',
      NAO_TEM_TEMPO_DE_CONTRIBUIÇÃO: 'Usa-se esse status para identificar os clientes que ainda não alcançaram o tempo de contribuição para o benefício que solicitou.',
      NOVAS_MENSAGENS: '',
      OUTROS: '',
      POTENCIAL_CLIENTE: 'Significa que a pessoa passou pela triagem de Aposentadoria e a unidade naquele momento não exerce a função.',
      RETORNAR: 'Significa que a Assessoria irá retornar para o cliente.',
      SPAM: 'Significa que estamos na lista de transmissão de alguém que não deseja atendimento.',
      VAI_RETORNAR: 'Significa que o cliente irá retornar para a Assessoria.',
      DESMARCOU: 'Significa que o cliente desmarcou o agendamento.',
    },
    PROCESSO: {
      CANCELADO: '',
      ENGANO: 'Significa que a pessoa não reconhece à Assessoria ou entrou em contato com o número errado.',
      ENTRADA: '',
      RESPONDIDO: '',
      VAI_PENSAR: '',
    },
  },
};
export default SituacaoTooltip;
