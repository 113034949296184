import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'isButtonEnabled',
  initialState: true,
  reducers: {
    disableButtonAction() {
      return false;
    },
    enableButtonAction() {
      return true;
    },
  },
});

export default slice.reducer;
export const { disableButtonAction, enableButtonAction } = slice.actions;
