import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PropTypes from 'prop-types';

export default function PaginationComponent({ paginationData, handlePageChange }) {
  return (
    <Pagination>
      { paginationData.currentPage !== 1
        && (
          <>
            <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
            { paginationData.currentPage !== 2
            && paginationData.previousPage !== 2 && <Pagination.Ellipsis /> }
          </>
        )}
      { paginationData.previousPage && paginationData.previousPage !== 1
        && (
        <Pagination.Item
          onClick={() => handlePageChange(paginationData.previousPage)}
        >
          {paginationData.previousPage}
        </Pagination.Item>
        ) }
      <Pagination.Item active>{paginationData.currentPage}</Pagination.Item>
      { paginationData.nextPage && paginationData.nextPage !== paginationData.currentPage
        && (
        <Pagination.Item
          onClick={() => handlePageChange(paginationData.nextPage)}
        >
          {paginationData.nextPage}
        </Pagination.Item>
        )}
      { paginationData.lastPage && paginationData.lastPage !== 1
        && paginationData.nextPage !== paginationData.lastPage
        && paginationData.currentPage !== paginationData.lastPage
        && (
          <>
            { paginationData.nextPage !== (paginationData.lastPage - 1) && <Pagination.Ellipsis /> }
            <Pagination.Item
              onClick={() => handlePageChange(paginationData.lastPage)}
            >
              {paginationData.lastPage}
            </Pagination.Item>
          </>
        )}
    </Pagination>
  );
}

PaginationComponent.defaultProps = {
  paginationData: {
    lastPage: 0,
    previousPage: null,
    nextPage: null,
    totalElements: 0,
    totalPerPage: 0,
    currentPage: 1,
  },
  handlePageChange: (e) => e,
};

PaginationComponent.propTypes = {
  paginationData: PropTypes.shape({
    lastPage: PropTypes.number,
    previousPage: PropTypes.number,
    nextPage: PropTypes.number,
    totalElements: PropTypes.number,
    totalPerPage: PropTypes.number,
    currentPage: PropTypes.number,
  }),
  handlePageChange: PropTypes.func,
};
