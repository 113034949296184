import { HdrOffSelect } from '@mui/icons-material';
import React, { useState, useContext } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';

import api from '../../../config/configApi';

import { Context } from '../../../Context/AuthContext';

export function Login() {
  const { state } = useLocation();

  const history = useHistory();

  const { signIn } = useContext(Context);

  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const [status, setStatus] = useState({
    type: state ? state.type : '',
    mensagem: state ? state.mensagem : '',
    loading: false,
  });

  const valorInput = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const loginSubmit = async (e) => {
    e.preventDefault();
    // console.log(user.password);
    setStatus({
      loading: true,
    });

    const headers = {
      'Content-Type': 'application/json',
    };

    await api.post('/login/login', user, { headers })
      .then((response) => {
        /* console.log(response); */
        setStatus({
          /* type: 'success',
                    mensagem: response.data.mensagem, */
          loading: false,
        });
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('name', response.data.user.nome);
        localStorage.setItem('email', user.email);
        localStorage.setItem('image', response.data.user.image);
        localStorage.setItem('empresaId', response.data.user.empresaId);
        localStorage.setItem('equipeId', response.data.user.equipeId);
        localStorage.setItem('matricula', response.data.user.matricula);
        localStorage.setItem('perfilId', response.data.user.perfilId);
        localStorage.setItem('urlfoto', response.data.user.urlfoto);
        signIn(true);
        return history.push('/pessoas');
      }).catch((err) => {
        if (err.response) {
          // console.log(err.response);
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
            loading: false,
          });
        } else {
          // console.log("Erro: tente mais tarde");
          setStatus({
            type: 'error',
            mensagem: 'Erro: tente mais tarde!',
            loading: false,
          });
        }
      });
  };

  return (
    <div id="wrapper">
      <div id="content-wrapper">
        <div id="content">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="container-fluid">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-10 col-lg-12 col-md-9">
                      <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body text-bg-dark p-0">
                          <br />
                          <br />
                          <div className="row">
                            <div className="col-lg-6 d-none d-lg-flex">
                              <br />
                              <br />
                              <img
                                style={{
                                  display: 'block',
                                  WebkitUserSelect: 'none',
                                  margin: 'auto',
                                  cursor: 'zoom-in',
                                  transition: 'background-color 300ms',
                                }}
                                src="/img/logo.png"
                                width="auto"
                                height="210"
                                alt="Minha Figura"
                              />
                            </div>

                            <div className="col-lg-6">
                              <div className="p-5">
                                <div className="text-center">
                                  <h1 className="h4  mb-4">Área Restrita</h1>
                                </div>
                                <form onSubmit={loginSubmit}>
                                  {status.type === 'error' ? (
                                    <p className="alert-danger">
                                      {status.mensagem}
                                    </p>
                                  ) : (
                                    ''
                                  )}
                                  {status.type === 'success' ? (
                                    <p className="alert-success">
                                      {status.mensagem}
                                    </p>
                                  ) : (
                                    ''
                                  )}

                                  {status.loading ? (
                                    <p className="alert-success">Validando...</p>
                                  ) : (
                                    ''
                                  )}

                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="email"
                                      className="form-control form-control-user"
                                      aria-describedby="emailHelp"
                                      placeholder="Enter Email Address..."
                                      onChange={valorInput}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="password"
                                      name="password"
                                      className="form-control form-control-user"
                                      id="exampleInputPassword"
                                      placeholder="Password"
                                      autoComplete="on"
                                      onChange={valorInput}
                                    />
                                  </div>

                                  <div className="form-group">
                                    {status.loading ? (
                                      <button
                                        type="submit"
                                        className="btn btn-primary btn-user btn-block"
                                        disabled
                                      >
                                        Acessando...
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="btn btn-primary btn-user btn-block"
                                      >
                                        Acessar
                                      </button>
                                    )}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
