import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://marcelomanhaes.herokuapp.com/',
  // baseURL: 'http://localhost:3001',
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if ([401, 403].includes(error.response?.status)) {
      localStorage.clear();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
