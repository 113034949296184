const chartOptions = (chartName) => ({
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: chartName,
      font: {
        size: 16,
        weight: 'bold',
      },
    },
  },
});

export default chartOptions;
