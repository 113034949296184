import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../Context/AuthContext';

import { Blank } from '../../pages/Blank';

export function Sidebar(props) {
  const { handleLogout } = useContext(Context);

  const [style, setStyle] = useState('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion');

  const changeStyle = () => {
    if (style == 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion') {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled');
    } else {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion');
    }
  };
  const changeStyle1 = () => {
    if (style == 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion') {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1');
    } else {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion');
    }
  };

  return (

    <>

    </>

  );
}
