import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Spinner, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';
import PaginationComponent from '../../Shared/Pagination';
import EmptyState from '../../Shared/EmptyState';
import personStatusTranslate from '../../../utils/enums/personStatusTranslate';
import personStatus from '../../../utils/enums/personStatus';

import api from '../../../config/configApi';

// eslint-disable-next-line import/prefer-default-export
export function Pessoas() {
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [unidadeId, setUnidadeId] = useState(() => (localStorage.getItem('pesquisaUnidade')
    ? localStorage.getItem('pesquisaUnidade')
    : ''));

  const [unidades, setUnidades] = useState([]);

  const [currentPage, setCurrentPage] = useState(() => (localStorage.getItem('pesquisaPessoaPagina')
    ? localStorage.getItem('pesquisaPessoaPagina')
    : 1));
  const [paginationData, setPaginationData] = useState();
  const [origens, setOrigens] = useState([]);
  const [origemdasligacoes, setOrigemDasLigacoes] = useState(() => (localStorage.getItem('pesquisaPessoaOrigem')
    ? localStorage.getItem('pesquisaPessoaOrigem')
    : ''));
  const [situacao, setSituacao] = useState(() => (localStorage.getItem('pesquisaPessoaSituacao')
    ? localStorage.getItem('pesquisaPessoaSituacao')
    : ''));
  const [contrato, setContrato] = useState(() => (localStorage.getItem('pesquisaContrato')
    ? localStorage.getItem('pesquisaContrato')
    : ''));
  const [spinnerSearch, setSpinnerSearch] = useState();
  const handleSituacaoChange = (e) => {
    setSituacao(e.target.value);
  };

  const armazenarNoLocalStorage = (chave, valor) => {
    localStorage.setItem(chave, valor);
  };

  const [search, setSearch] = useState({
    search: localStorage.getItem('pesquisaPessoaConsulta') || '',
    pesquisarpor: localStorage.getItem('pesquisaPessoaPesquisarPor') || 'nome',
  });

  const armazenarPesquisaLocalStorage = () => {
    armazenarNoLocalStorage(
      'pesquisaPessoaConsulta',
      search.search,
    );
    armazenarNoLocalStorage(
      'pesquisaPessoaPesquisarPor',
      search.pesquisarpor,
    );
    armazenarNoLocalStorage(
      'pesquisaPessoaOrigem',
      origemdasligacoes,
    );
    armazenarNoLocalStorage(
      'pesquisaPessoaSituacao',
      situacao,
    );
    armazenarNoLocalStorage(
      'pesquisaContrato',
      contrato,
    );
    armazenarNoLocalStorage(
      'pesquisaPessoaPagina',
      1,
    );
    armazenarNoLocalStorage(
      'pesquisaUnidade',
      unidadeId,
    );
  };
  const [status, setStatus] = useState({
    type: state ? state.type : '',
    mensagem: state ? state.mensagem : '',
  });

  const exportarPesquisa = async () => {
    try {
      const headers = {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };

      let origemDasLigacoes;
      let contratoAssinado;

      if (origemdasligacoes) origemDasLigacoes = `&origemdasligacoes=${origemdasligacoes}`;
      if (contrato === 'sim') {
        contratoAssinado = 'S';
      } else if (contrato === 'nao') {
        contratoAssinado = 'N';
      } else {
        contratoAssinado = '';
      }

      const response = await api
        .get(
          `/pessoa?${search.pesquisarpor}=${
            search.search
          }&${
            origemDasLigacoes || ''
          }&situacaoStatus=${situacao}&assinouContrato=${contratoAssinado}`,
          { headers, responseType: 'blob' },
        );

      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'pessoas-export.xlsx';

      if (contentDisposition) {
        console.log('contentDisposition:', contentDisposition);
        const [, matchedFileName] = contentDisposition.match(/attachment; filename=(.*?\.xlsx)/) || [];
        console.log('matchedFileName:', matchedFileName);
        if (matchedFileName) {
          fileName = matchedFileName;
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setStatus({
        type: 'error',
        mensagem: 'Não foi possível exportar a pesquisa',
      });
    }
  };

  const valueInput = (e) => setSearch({ ...search, [e.target.name]: e.target.value });

  const [page, setPage] = useState('');
  const [lastPage, setLastPage] = useState('');
  const getOrigens = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    await api
      .get('/origem/origens', headers)
      .then((response) => {
        setOrigens(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  const getAssinouContrato = async (assinouContrato) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const response = await api.get(
        `/contrato/assinado?assinouContrato=${assinouContrato}`,
        headers,
      );
      return response.data;
    } catch (error) {
      console.error('Erro ao obter informações sobre o contrato:', error);
      throw error;
    }
  };
  const getUsers = async (page = 1) => {
    setSpinnerSearch(true);
    let origemDasLigacoes;
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    if (origemdasligacoes) origemDasLigacoes = `&origemdasligacoes=${origemdasligacoes}`;
    const contratoAssinado = contrato === 'sim' ? 'S' : contrato === 'nao' ? 'N' : '';
    await api
      .get(
        `/pessoa?page=${page}&perPage=20&${search.pesquisarpor}=${
          search.search
        }&${
          origemDasLigacoes || ''
        }&situacaoStatus=${situacao}&assinouContrato=${contratoAssinado}&unidadeId=${unidadeId}`,
        headers,
      )

      .then((response) => {
        setData(response.data.result);
        setPaginationData({
          lastPage: response?.data?.lastPage,
          previousPage: response?.data?.previousPage,
          nextPage: response?.data?.nextPage,
          totalElements: response?.data?.totalElements,
          totalPerPage: response?.data?.totalPerPage,
          currentPage: response?.data?.currentPage,
        });
        setLastPage(response.data.lastPage);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
    setSpinnerSearch(false);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      armazenarPesquisaLocalStorage();
      getUsers();
    }
  };
  const handlePageChange = async (page) => {
    armazenarNoLocalStorage('pesquisaPessoaPagina', page);
    await getUsers(page);
    setCurrentPage(page);
  };

  const getUnidades = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    await api
      .get('/unidade/unidades/', headers)
      .then((response) => {
        setUnidades(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };

  useEffect(() => {
    getUnidades();
    getOrigens();
    getUsers(currentPage);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              {/*  <!-- Page Heading --> */}
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <div className="mb-0 text-primary">
                  <small>
                    {' '}
                    {paginationData?.totalElements}
                    {' '}
                    resultados
                  </small>
                </div>
              </div>

              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <div className="row align-items-center">
                    <div className="col-lg-9 col-md-12">
                      <div className="row align-items-center">
                        <div className="col-lg-3 col-md-6">
                          <input
                            type="text"
                            name="search"
                            id="search"
                            className="form-control  text-uppercase"
                            placeholder="pesquisar por"
                            onChange={valueInput}
                            defaultValue={localStorage.getItem(
                              'pesquisaPessoaConsulta',
                            )}
                            onKeyDown={handleKeyPress}
                          />
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <select
                            className="custom-select my-1 mr-sm-2"
                            name="pesquisarpor"
                            id="pesquisarpor"
                            placeholder="Pesquisar Por"
                            onChange={valueInput}
                            defaultValue={localStorage.getItem(
                              'pesquisaPessoaPesquisarPor',
                            )}
                            required
                          >
                            <option value="nome" selected>
                              Pesquisar por Nome
                            </option>
                            <option value="id">Código</option>
                            <option value="telefone">Telefone</option>
                            <option value="cpf">CPF</option>
                            <option value="observacao">Observação</option>
                            <option value="situacaoStatus">Situação</option>
                            <option value="pagamentoStatus">Pagamento</option>
                            <option value="numeroRequerimento">Requerimento</option>

                          </select>
                        </div>

                        <div className="col-lg-2 col-md-6">
                          <select
                            className="custom-select my-1 mr-sm-2"
                            name="unidade"
                            id="unidade"
                            placeholder="unidade"
                            onChange={(e) => setUnidadeId(e.target.value)}
                            value={unidadeId}
                          >
                            <option value="">Todas as Unidades</option>
                            {unidades?.unidades?.map((unidadeOpcao) => (
                              <option key={unidadeOpcao.id} value={unidadeOpcao.id}>
                                {unidadeOpcao.nome}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-lg-2 col-md-6">
                          <select
                            className="custom-select my-1 mr-sm-2"
                            name="origemdasligacoes"
                            defaultValue={localStorage.getItem(
                              'pesquisaPessoaOrigem',
                            )}
                            id="origemdasligacoes"
                            placeholder="origemdasligacoes"
                            onChange={(text) => setOrigemDasLigacoes(text.target.value)}
                          >
                            <option value="">Origem</option>
                            {origens?.origens?.map((item) => (
                              <option
                                key={item.nome}
                                value={item.nome}
                                selected={item.nome === origemdasligacoes}
                              >
                                {item.nome}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-lg-2 col-md-6">
                          <select
                            className="custom-select my-1 mr-sm-2"
                            id="situacao"
                            name="situacao"
                            value={situacao}
                            defaultValue={localStorage.getItem(
                              'pesquisaPessoaSituacao',
                            )}
                            onChange={handleSituacaoChange}
                          >
                            <option value="">Selecione a situação</option>
                            <optgroup label="Análise">
                              {Object.entries(personStatus.ANALISE).map(
                                ([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ),
                              )}
                            </optgroup>
                            <optgroup label="Decisão">
                              {Object.entries(personStatus.DECISAO).map(
                                ([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ),
                              )}
                            </optgroup>
                            <optgroup label="Interação">
                              {Object.entries(personStatus.INTERACAO).map(
                                ([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ),
                              )}
                            </optgroup>
                            <optgroup label="Processo">
                              {Object.entries(personStatus.PROCESSO).map(
                                ([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ),
                              )}
                            </optgroup>
                            <optgroup label="Outros">
                              {Object.entries(personStatus.OUTROS).map(
                                ([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ),
                              )}
                            </optgroup>
                          </select>
                        </div>
                        <div className="col-lg-2 col-md-6">
                          <select
                            className="custom-select my-1 mr-sm-2"
                            name="contrato"
                            id="contrato"
                            placeholder="Contrato"
                            onChange={(e) => setContrato(e.target.value)}
                            defaultValue={localStorage.getItem(
                              'pesquisaContrato',
                            )}
                            required
                          >
                            <option value="" selected>
                              Assinou o Contrato?
                            </option>
                            <option value="sim">Sim</option>
                            <option value="nao">Não</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <Link to="#" style={{ textDecoration: 'none' }}>
                            <button
                              type="button"
                              onClick={() => {
                                armazenarPesquisaLocalStorage();
                                getUsers();
                              }}
                              className="btn btn-primary btn-block"
                            >
                              Localizar
                            </button>
                          </Link>
                        </div>
                        <div className="col-md-6">
                          <Link
                            to="/add-pessoa"
                            style={{ textDecoration: 'none' }}
                          >
                            <button
                              type="button"
                              className="btn btn-success btn-block"
                            >
                              Cadastrar
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="d-flex align-items-center justify-content-end">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-pessoa-arquivos"
                            className="btn-block"
                          >
                            <FontAwesomeIcon icon={faFile} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              href="#"
                              onClick={exportarPesquisa}
                            >
                              Exportar Pesquisa
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12 col-md-6">
                      <div className="alert-content-adm">
                        {status.type === 'error' ? (
                          <p className="alert-danger">{status.mensagem}</p>
                        ) : (
                          ''
                        )}
                        {status.type === 'success' ? (
                          <p className="alert-success">{status.mensagem}</p>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div className="card-body">
                    {spinnerSearch ? (
                      <div className="d-flex justify-content-center">
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          role="status"
                          aria-hidden="true"
                        >
                          <span className="sr-only">Buscando...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <>
                        <table className="table table-sm">
                          <thead>
                            <tr>
                              <th hidden>ID</th>
                              <th>Nome</th>
                              <th>Benefício</th>
                              <th>Data de Nascimento</th>
                              <th>Unidade</th>
                              <th>Indicação</th>
                              <th>Bairro</th>
                              <th>Telefone</th>
                              <th>Situação</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((user) => (
                              <tr key={user.id}>
                                <td hidden>{user.id}</td>
                                <td>
                                  <Link
                                    to={`/edit-pessoa/${user.id}`}
                                    style={{ textDecoration: 'none' }}
                                  >
                                    {user.nome}
                                  </Link>
                                </td>

                                <td>{user.beneficio}</td>
                                <td>{user.datanascimento ? `${user.datanascimento?.substring(8, 10)}/${user.datanascimento?.substring(5, 7)}/${user.datanascimento?.substring(0, 4)}` : ''}</td>
                                <td>{user.unidade?.nome}</td>
                                <td>{user.indicacao}</td>
                                <td>{user.bairro}</td>
                                <td>
                                  {' '}
                                  {user.telefoneprincipal !== '' ? (
                                    <a
                                      style={{ textDecoration: 'none' }}
                                      href={
                                        `https://web.whatsapp.com/send/?phone=55${
                                          user.telefoneprincipal}`
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {user.telefoneprincipal}
                                    </a>
                                  ) : (
                                    'Não Informado'
                                  )}
                                </td>
                                <td>
                                  {user.situacaoStatus ? personStatusTranslate[user.situacaoStatus] : ''}
                                </td>

                                <td>
                                  <Link
                                    to={`/edit-pessoa/${user.id}`}
                                    style={{ textDecoration: 'none' }}
                                  >
                                    <button
                                      className="btn btn-primary btn-block"
                                      type="button"
                                    >
                                      <i className="fas fa-edit fa-sm" />
                                      {' '}
                                      Ver
                                    </button>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <EmptyState isActive={!data.length} />
                      </>
                    )}
                    {!!data.length && (
                      <PaginationComponent
                        paginationData={paginationData}
                        handlePageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
