import React, { useEffect, useState } from 'react';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar';

import 'moment/locale/pt-br';
import axios from 'axios';

export function Portfolio() {
  const moment = require('moment');

  const [countdown, setCountdown] = useState(300000);
  const [estagio, setEstagio] = useState('Normalidade');
  const [tempoestagio, setTempoEstagio] = useState(' ');

  const getAlertas = async () => {
    console.count(`Reiniciou getAlertas   ${moment(Date.now()).format('DD/MM/YYYY HH:mm:ss')}`);
    await axios({
      method: 'get',
      baseURL: 'http://aplicativo.cocr.com.br/estagio_api',

    }).then((estagioatual) => {
      try {
        setTempoEstagio(`${moment(estagioatual.data.inicio).format('DD/MM/YYYY HH:mm')} ` + `(${moment(estagioatual.data.inicio).startOf('hour').fromNow()}` + ')');
        setEstagio(estagioatual.data.estagio);
      } catch (error) {
        console.log(error);
        setTempoEstagio('Não conseguimos o acesso a api que retorna informações do Estágio Atual da Cidade');
        setEstagio('Branco');
      }
    }).catch((err) => {
      console.log(err);
      setTempoEstagio('Não conseguimos o acesso a api que retorna informações do Estágio Atual da Cidade');
      setEstagio('Branco');
    });
  };
  useEffect(() => { getAlertas(); }, []);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCountdown((prevState) => prevState - 1);

      getAlertas();
    }, countdown);
    return () => clearInterval(timerId);
  }, [countdown]);

  return (
    <div>
      <Navbar />

      <div className="content">

        <Sidebar active="portfolio" />

        <div className="wrapper">

          <div className="table-responsive">
            <div className="row">
              <div className="top-content-adm">

                <div className="top-content-adm-left">
                  {estagio === 'Normalidade' ? (
                      <>
                          <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${estagio}`} aria-expanded="false" aria-controls={`collapseExample${estagio}`}>
                              <img className="estagio" src="/normalidade.png" alt="Normalidade" />
                            </button>
                          <div className="collapse" id={`collapseExample${estagio}`}>
                              <div className="card card-body">
                                  <spam>
                                  A Cidade Entrou em Estágio de:
                          <strong>{estagio}</strong>
                                  {' '}
                                  Na Data:
                          <strong>{tempoestagio}</strong>
                                </spam>
                                  <span>
                                  <strong>Quando:</strong>
                                  {' '}
                                  Não há ocorrências que provoquem alteração significativa no dia a dia do carioca. Não foram identificados fatores de risco de curto prazo que impactem a rotina da cidade
                        </span>
                                  <span>
                                  <strong>Impacto:</strong>
                                  {' '}
                                  Sem ou com pouco impacto para a fluidez do trânsito e das operações de infraestrutura e logística da cidade.
                        </span>
                                </div>
                            </div>
                        </>
                    )
                      : '' }

                  {estagio === 'Mobilizacao' ? (
                      <>
                          <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${estagio}`} aria-expanded="false" aria-controls={`collapseExample${estagio}`}>
                              <img className="estagio" src="/mobilizacao.png" alt="Mobilização" />
                            </button>
                          <div className="collapse" id={`collapseExample${estagio}`}>
                              <div className="card card-body">
                                  <spam>
                                  A Cidade Entrou em Estágio de:
                          <strong>{estagio}</strong>
                                  {' '}
                                  Na Data:
                          <strong>{tempoestagio}</strong>
                                </spam>
                                  <span>
                                  <strong>Quando:</strong>
                                  Risco de haver ocorrências de alto impacto na cidade, devido a um evento previsto ou a partir da análise de dados provenientes de especialistas. Há ocorrência com elevado potencial de agravamento.
                        </span>
                                  <span>
                                  <strong>Impacto:</strong>
                                  Ainda não há impactos na rotina da cidade, mas os cidadãos devem se manter informados.
                        </span>
                                </div>
                            </div>
                        </>
                    )
                      : '' }

                  {estagio === 'Atencao' ? (
                      <>
                          <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${estagio}`} aria-expanded="false" aria-controls={`collapseExample${estagio}`}>
                              <img className="estagio" src="/atencao.png" alt="Atenção" />
                            </button>
                          <div className="collapse" id={`collapseExample${estagio}`}>
                              <div className="card card-body">
                                  <spam>
                                  A Cidade Entrou em Estágio de:
                              <strong>{estagio}</strong>
                                  {' '}
                                  Na Data:
                              <strong>{tempoestagio}</strong>
                                </spam>
                                  <span>
                                  <strong>Quando:</strong>
                                  Uma ou mais ocorrências estão impactando a cidade. Há certeza de que haverá ocorrência de alto impacto, no curto prazo.
                            </span>
                                  <span>
                                  <strong>Impacto:</strong>
                                  Pelo menos uma região da cidade está impactada, causando reflexos relevantes na infraestrutura e logística urbana, e afetando diretamente a rotina da população (ou de parte dela).
                            </span>
                                </div>
                            </div>
                        </>
                    )
                      : ''}

                  {estagio === 'Alerta' ? (
                      <>
                          <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${estagio}`} aria-expanded="false" aria-controls={`collapseExample${estagio}`}>
                              <img className="estagio" src="/alerta.png" alt="Alerta" />
                            </button>
                          <div className="collapse" id={`collapseExample${estagio}`}>
                              <div className="card card-body">
                                  <spam>
                                  A Cidade Entrou em Estágio de:
                              <strong>{estagio}</strong>
                                  {' '}
                                  Na Data:
                              <strong>{tempoestagio}</strong>
                                </spam>
                                  <span>
                                  <strong>Quando:</strong>
                                  Uma ou mais ocorrências graves impactam a cidade ou há incidência simultânea de diversos problemas de médio e alto impacto em diferentes regiões da cidade. Os múltiplos danos e impactos causados começam a extrapolar a capacidade de resposta imediata das equipes da cidade.
                            </span>
                                  <span>
                                  <strong>Impacto:</strong>
                                  Uma ou mais regiões estão impactadas, causando reflexos graves / importantes na infraestrutura e logística urbana, e afetando severamente a rotina da população (ou de parte dela).
                            </span>
                                </div>
                            </div>
                        </>
                    )
                      : ''}

                  {estagio === 'Crise' ? (
                      <>
                          <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${estagio}`} aria-expanded="false" aria-controls={`collapseExample${estagio}`}>
                              <img className="estagio" src="/crise.png" alt="Alerta" />
                            </button>
                          <div className="collapse" id={`collapseExample${estagio}`}>
                              <div className="card card-body">
                                  <spam>
                                  A Cidade Entrou em Estágio de:
                              <strong>{estagio}</strong>
                                  {' '}
                                  Na Data:
                              <strong>{tempoestagio}</strong>
                                </spam>
                                  <span>
                                  <strong>Quando:</strong>
                                  Uma ou mais ocorrências graves impactam a cidade ou há incidência simultânea de diversos problemas de médio e alto impacto em diferentes regiões da cidade. Os múltiplos danos e impactos causados extrapolam de forma relevante a capacidade de resposta imediata das equipes da cidade.
                            </span>
                                  <span>
                                  <strong>Impacto:</strong>
                                  Uma ou mais regiões estão impactadas, causando reflexos graves / importantes na infraestrutura e logística urbana, e afetando severamente a rotina da população (ou de parte dela).
                            </span>
                                </div>
                            </div>
                        </>
                    )
                      : ''}

                  {estagio === 'Branco' ? (
                      <>
                          <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${estagio}`} aria-expanded="false" aria-controls={`collapseExample${estagio}`}>
                              <img className="estagio" src="/branco.png" alt="Branco" />
                            </button>
                          <div className="collapse" id={`collapseExample${estagio}`}>
                              <div className="card card-body">
                                  <spam>
                                  <strong>
                                  {estagio}
                                  {' '}
                                  {tempoestagio}
                                </strong>
                                </spam>
                                </div>
                            </div>
                        </>
                    )
                      : ''}
                </div>

                <div className="top-content-adm-right">

                  <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                      <img className="seta" src="/icone_usuarioseta.png" alt="MENU" />
                      <spam className="top-content-adm">Opções</spam>
                    </button>
                  <div className="collapse" id="collapseExample">
                      <div className="card card-body">

                          <div className="top-content-adm-left">
                              {' '}
                              <a href="https://cor.rio/paineldocomando"><input type="button" className="btn-info" value="Sala de Situação" /></a>
                              {' '}
                            </div>

                        </div>
                    </div>

                </div>

              </div>
            </div>
          </div>

          <div
            className="row"
            style={{
              background: '#ECEDF0', height: 250, width: '100%', marginBottom: '10px',
            }}
          >

            <div className="card" style={{ width: '400px' }}>
              <img className="card-img-top" src="/operacoesrio.png" alt="Card image cap" />
              <div className="card-body">
                <h5 className="card-title">Painel de Ocorrências</h5>
                <p className="card-text">Informações em tempo real sobre as principais ocorrências da Cidade.</p>
                <a href="/dashboard" className="btn btn-primary">Visualizar</a>
              </div>
            </div>

            <div className="card" style={{ width: '400px' }}>
              <img className="card-img-top" src="/saladesituacao.png" alt="Card image cap" />
              <div className="card-body">
                <h5 className="card-title">Painel Sala de Situação</h5>
                <p className="card-text">Dashboard Painel do Comando para visualização das Ocorrências Abertas e Fechadas pelos demais órgãos da Prefeitura. Também um histórico das ocorrências.</p>
                <a href="/operacoesrio" className="btn btn-primary">Visualizar</a>
              </div>
            </div>

            <div className="card" style={{ width: '400px' }}>
              <img className="card-img-top" src="/rioaguas.png" alt="Card image cap" />
              <div className="card-body">
                <h5 className="card-title">Pontos de Monitoramento RIO-ÁGUAS</h5>
                <p className="card-text">Monitoramento dos Pontos idetificados pela Rio-Águas e a integração de informações de Ocorrências Climáticas do Comando.</p>
                <a href="/rioaguas" className="btn btn-primary">Visualizar</a>
              </div>
            </div>
            <div className="card" style={{ width: '400px' }}>
              <img className="card-img-top" src="/eventos.png" alt="Card image cap" />
              <div className="card-body">
                <h5 className="card-title">Eventos Mapeados</h5>
                <p className="card-text">Monitoramento de Eventos, público estimado e sua distribuição nas regiões da Cidade.</p>
                <a href="/eventos" className="btn btn-primary">Visualizar</a>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  );
}
