import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert, Spinner, Dropdown, Form,
} from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import pdfMake from 'pdfmake/build/pdfmake';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import api from '../../../config/configApi';
import AtendimentoForm from './AtendimentoForm';
import { formularioPadraoContent } from './formularioPadrao';
import pdfFonts from '../../../utils/fonts/vfs_fonts';
import { termoRepresentacaoContent } from './termoRepresentacao';
import { procuracaoContent } from './procuracao';
import { brazillianTime } from '../../../utils/date';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const fonts = {
  Arial: {
    normal: 'ARIAL.TTF',
    bold: 'ARIALBD.TTF',
    italics: 'ARIALI.TTF',
    bolditalics: 'ARIALBI.TTF',
  },
};

export default function Atendimento() {
  const { id, atendimentoId } = useParams();
  const [pessoa, setPessoa] = useState({});
  const [beneficios, setBeneficios] = useState([]);
  const [alertError, setAlertError] = useState({ isVisible: false });
  const [alertSuccess, setAlertSuccess] = useState({ isVisible: false });
  const [isAllowedFinalizarAtendimento, setIsAllowedFinalizarAtendimento] = useState(false);
  const [spinnerFinalizar, setSpinnerFinalizar] = useState(false);
  const [isAtendimentoEncerrado, setIsAtendimentoEncerrado] = useState(false);
  const [isEnabledFormularios, setIsEnabledFormularios] = useState(false);
  const [userId, setUserId] = useState();
  const [atendenteId, setAtendenteId] = useState();
  const [atendentes, setAtendentes] = useState([]);
  const [dataAtendimento, setDataAtendimento] = useState(
    new Date().toISOString().split('T')[0],
  );

  const [dataDoAtendimentoHota, setDataDoAtendimentoHora] = useState(
    brazillianTime(new Date().toISOString()).split('/'),
  );

  const updateAllowedFinalizarAtendimento = (status) => {
    setIsAllowedFinalizarAtendimento(status);
  };

  const enableFormularios = () => {
    setIsEnabledFormularios(true);
  };
  const getAtendente = async () => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    const response = await api.get('/profile/view-profile', headers);
    setUserId(response?.data?.user?.id);
    setAtendenteId(response?.data?.user?.id);
  };

  const getAtendentes = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await api.get('/user/atendentes', headers);
    setAtendentes(response.data);
  };

  const finalizarAtendimento = async () => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    setSpinnerFinalizar(true);

    try {
      const atendimentoDateTime = new Date(`${dataAtendimento} ${dataDoAtendimentoHota}`);
      await api.patch(`/atendimentos-presenciais/${atendimentoId}/finalizar`, { atendenteId, realizadoEm: atendimentoDateTime }, headers);

      setAlertSuccess({ isVisible: true, message: 'Atendimento finalizado com sucesso!' });
      setIsAtendimentoEncerrado(true);
      window.setTimeout(() => { setAlertSuccess({ isVisible: false }); }, 5000);
    } catch (err) {
      setAlertError({ isVisible: true, message: err.response.data.mensagem || 'Não foi possível finalizar este atendimento' });
      window.setTimeout(() => { setAlertError({ isVisible: false }); }, 5000);
    }
    setSpinnerFinalizar(false);
  };

  const getUser = async () => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    try {
      const { data } = await api.get(`/pessoa/pessoa/${id}`, headers);
      setPessoa(data.user);
      return data.user;
    } catch (error) {
      setAlertError({ isVisible: true, message: error.response.data.mensagem || 'Aconteceu um erro, por favor tente novamente.' });
      window.setTimeout(() => { setAlertError({ isVisible: false }); }, 5000);
      return undefined;
    }
  };

  const generarFormularioPadrao = async () => {
    const updatedPessoa = await getUser();
    const dd = formularioPadraoContent(updatedPessoa);
    pdfMake.createPdf(dd, null, fonts).print();
  };

  const generateTermoRepresentacao = async () => {
    const updatedPessoa = await getUser();
    const dd = termoRepresentacaoContent(updatedPessoa);
    pdfMake.createPdf(dd, null, fonts).print();
  };

  const gerarProcuracao = async () => {
    const updatedPessoa = await getUser();
    const dd = procuracaoContent(updatedPessoa);
    pdfMake.createPdf(dd, null, fonts).print();
  };

  useEffect(() => {
    getUser();
    getAtendente();
    getAtendentes();
  }, []);

  return (
    <>
      <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Navbar />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">Atendimento</h1>
            </div>
            
              <div className="row">
                <div className="col-xl-12 col-md-12 mb-4">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <div className="row">
                        
                        <div className="col-10">
                          
                          <h4 className="h4 mb-0 text-gray-800">
                              <a href={`/edit-pessoa/${pessoa.id}`}>{pessoa.nome}</a>
                          </h4>
                        </div>
                              
                        
                        <div className="col-2">
                          <div className="d-sm-flex align-items-center justify-content-between float-right">
                            <Dropdown>
                              <Dropdown.Toggle variant="secondary" id="dropdown-pessoa-arquivos">
                                <FontAwesomeIcon icon={faFile} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={generarFormularioPadrao} disabled={!isEnabledFormularios}>
                                  <FontAwesomeIcon icon={faFilePdf} />
                                  Formulário Padrão
                                </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={generateTermoRepresentacao} disabled={!isEnabledFormularios}>
                                  <FontAwesomeIcon icon={faFilePdf} />
                                  Termo de Representação e Autorização
                                </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={gerarProcuracao} disabled={!isEnabledFormularios}>
                                  <FontAwesomeIcon icon={faFilePdf} />
                                  Procuracao
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      { !isEmpty(pessoa)
                        ? (
                          <AtendimentoForm
                            pessoa={pessoa}
                            updateAllowedFinalizarAtendimento={updateAllowedFinalizarAtendimento}
                            enableFormularios={enableFormularios}
                          />
                        ) : (
                          <div className="d-flex justify-content-center">
                            <Spinner
                              as="span"
                              animation="border"
                              size="xl"
                              role="status"
                              aria-hidden="true"
                            >
                              <span className="sr-only">Carregando...</span>
                            </Spinner>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="card shadow mb-4">
              { userId && (
              <div className="card shadow mb-4">
                <div className="card-header">
                  <p className="mt-4">DADOS DO ATENDENTE</p>
                  <div className="form-row">
                    <Form.Group className="col-xl-2 col-md-6 mb-4" controlId="validationFormikAtendenteId">
                      <Form.Label>Atendente</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="atendenteId"
                        value={atendenteId || ''}
                        onChange={(e) => setAtendenteId(e.target.value)}
                      >
                        <option disabled selected>Selecione uma opção</option>
                        {atendentes?.atendentes?.map((item) => (
                      <option key={item.id} value={item.id} selected= {item.id.toString() === userId.toString()}>
                        {item.nome}
                      </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  <Form.Group className="col-xl-2 col-md-6 mb-4" controlId="validationFormikDataAtendimento">
                    <Form.Label>Data</Form.Label>
                    <Form.Control
                      type="date"
                      name="dataatendimento"
                      value={ dataAtendimento}
                      onChange={(e) => setDataAtendimento(e.target.value)}
                    />
                    <Form.Control.Feedback />
                  </Form.Group>
                  <Form.Group className="col-xl-2 col-md-6 mb-4" controlId="validationFormikDataAtendimento">
                    <Form.Label>Hora</Form.Label>
                    <Form.Control
                      type="time"
                      name="dataatendimentohora"
                      value={ dataDoAtendimentoHota}
                      onChange={(e) => setDataDoAtendimentoHora(e.target.value)}
                    />
                    <Form.Control.Feedback />
                  </Form.Group>
                  
                  <div className="col-xl-6 col-md-6 mb-4">
                  <label htmlFor="espaco">&nbsp;</label>
                  <button type="submit" onClick={finalizarAtendimento} className={`btn btn-success btn-block ${!isAllowedFinalizarAtendimento ? 'disabled' : ''}`}>
                    
                        { spinnerFinalizar
                          ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            >
                              <span className="sr-only">Finalizando...</span>
                            </Spinner>
                          ) : 'Finalizar Atendimento'}
                      </button>
                  </div>
                  </div>
                </div>
              </div>
                      
              )}
                      
                  </div>

                    { alertError.isVisible
                    && (
                    <div className="fixed-top mt-5">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-6">
                            <Alert variant="danger" onClose={() => setAlertError({ isVisible: false })} dismissible>
                              <p className="text-center mt-3">
                                {alertError.message}
                              </p>
                            </Alert>
                          </div>
                        </div>
                      </div>
                    </div>
                    )}
                  { alertSuccess.isVisible
                    && (
                    <div className="fixed-top mt-5">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-6">
                            <Alert variant="success" onClose={() => setAlertSuccess({ isVisible: false })} dismissible>
                              <p className="text-center mt-3">
                                {alertSuccess.message}
                              </p>
                            </Alert>
                          </div>
                        </div>
                      </div>
                    </div>
                    )}
                </div>
              </div>
            
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
