import React, { useContext } from 'react';
import Nav from 'react-bootstrap/Nav';
import { Context } from '../../Context/AuthContext';

function Footer() {
  const { authenticated } = useContext(Context);

  return (
    <footer className="footer mt-auto">
      <div className="container my-2 py-2">
        <Nav className="nav justify-content-center border-bottom py-2 my-2">
          <Nav.Item>
            <Nav.Link
              href="https://web.whatsapp.com/send/?phone=5521997811982&text=Oi"
              target="_blank"
              rel="noopener noreferrer"
            >
              Fale Conosco no Whatsapp
            </Nav.Link>
          </Nav.Item>
          { authenticated && (
            <Nav.Item>
              <Nav.Link href="/ajuda">
                Ajuda
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Nav className="nav justify-content-center">
          <p>
            <span>Copyright &copy; Computei 2024</span>
          </p>
        </Nav>
      </div>
    </footer>
  );
}

export default Footer;
