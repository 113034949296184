import React, { useState } from 'react';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar';
import { LogOutModal } from '../../components/LogOutModal';

export function Blank() {
  const [style, setStyle] = useState('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion');

  const changeStyle = () => {
    if (style == 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion') {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled');
    } else {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion');
    }
  };
  const changeStyle1 = () => {
    if (style == 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion') {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1');
    } else {
      setStyle('navbar-nav bg-gradient-primary sidebar sidebar-dark accordion');
    }
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid" />
          </div>
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
