import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { ChangeAlert } from '../../Shared/ChangeAlert';
import { brazillianTime, brazillianDate } from '../../../utils/date';
import api from '../../../config/configApi';
import TabelaAtendimentosPresenciais from '../../AtendimentosPresenciais/Index/TabelaAtendimentosPresenciais';

export default function EditPessoaAgendamento({ pessoaId, unidadeId, unidades }) {
  const [datadoagendamento, setDataDoAgendamento] = useState(
    () => {
      const splitedDate = brazillianDate(new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000)).toISOString()).split('/');
      return `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`;
    },
  );
  const [datadoagendamentohora, setDataDoAgendamentoHora] = useState(
    brazillianTime(new Date().toISOString()).split('/'),
  );

  const [formatedDataAgendamento, setFormatedDataAgendamento] = useState(() => {
    const [year, month, day] = datadoagendamento.split('-');
    return `${day}/${month}/${year}`;
  });

  const [unidade, setUnidade] = useState('');
  const [atendimentoUnidadeId, setAtendimentoUnidadeId] = useState(unidadeId);

  const [beneficios, setBeneficios] = useState([]);
  const [origens, setOrigens] = useState([]);
  const [atendentes, setAtendentes] = useState([]);
  const [indicacoes, setIndicacoes] = useState([]);
  const [lastPage, setLastPage] = useState('');

  const [formDirty, setFormDirty] = React.useState(false);
  const [dirtyCalledTimes, setDirtyCalledTimes] = React.useState(0);
  const [show, setShow] = useState(false);
  const [showChangeAlert, setShowChangeAlert] = useState(false);
  const [atendimentosPresenciais, setAtendimentosPresenciais] = useState([]);
  const [tiposAtendimento, setTiposAtendimento] = useState([]);
  const [tipoAtendimento, setTipoAtendimento] = useState(1);

  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  const handleClose = () => setShow(false);

  const markFormDirty = () => {
    setFormDirty(true);
    setDirtyCalledTimes((prevState) => prevState + 1);
  };

  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });

  const getTiposAtendimento = async () => {
    const atendimentoHeaders = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const res = await api.get('/tipos-de-atendimento', { ...atendimentoHeaders });

    setTiposAtendimento(res.data?.tiposdeatendimento);
  };

  const saveAtendimento = async (e) => {
    const formAgendamento = document.getElementById('form-agendamento');
    if (formAgendamento.reportValidity()) {
      try {
        await api.post('/atendimentos-presenciais', {
          unidadeId: atendimentoUnidadeId || unidadeId,
          tipoatendimentoId: tipoAtendimento,
          pessoaId,
          agendadopara: new Date(`${datadoagendamento} ${datadoagendamentohora}`),
        }, headers);
        window.location.reload();
      } catch (error) {
        console.log(error);
        alert('Não foi possível realizar o agendamento');
      }
    }
  };

  const getAtendimentosPresenciais = async (params) => {
    try {
      const { data } = await api.get('/atendimentos-presenciais', {
        headers: headers.headers,
        params,
      });

      setAtendimentosPresenciais(data.atendimentosPresenciais);
    } catch (error) {
      setAtendimentosPresenciais([]);
      setStatus({
        type: 'error',
        mensagem: 'Não foi possível carregar os atendimentos agendados',
      });
    }
  };

  useEffect(() => {
    if ((atendimentoUnidadeId || unidadeId) && datadoagendamento) {
      const [year, month, day] = datadoagendamento.split('-');
      const agendadoParaParam = `${day}/${month}/${year}`;
      setFormatedDataAgendamento(agendadoParaParam);
      const params = {
        unidadeId: atendimentoUnidadeId || unidade,
        agendadoParaDe: agendadoParaParam,
        agendadoParaAte: agendadoParaParam,
      };
      getAtendimentosPresenciais(params);
    }
  }, [datadoagendamento, unidade, atendimentoUnidadeId]);

  const [data, setData] = useState([]);

  const getBeneficios = async () => {
    await api
      .get('/beneficio/beneficios', headers)
      .then((response) => {
        setBeneficios(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getBeneficios();
  }, []);

  const getOrigens = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    await api
      .get('/origem/origens', headers)
      .then((response) => {
        setOrigens(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getOrigens();
  }, []);

  const getAtendentes = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    await api
      .get('/user/atendentes', headers)
      .then((response) => {
        setAtendentes(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getAtendentes();
    getTiposAtendimento();
  }, []);

  const getIndicacoes = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    await api
      .get('/indicacao/indicacoes', headers)
      .then((response) => {
        setIndicacoes(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getIndicacoes();
  }, []);

  return (
    <>
      <Form onChange={markFormDirty} id="form-agendamento">
        <div className="row">
          <div className="col-xl-2 col-md-6 mb-4">
            <label htmlFor="unidade">
              Unidade
            </label>
            <select
              className="custom-select my-1 mr-sm-2"
              name="unidade"
              id="unidade"
              required="required"
              value={atendimentoUnidadeId || unidadeId}
              onChange={(text) => setAtendimentoUnidadeId(text.target.value)}
            >
              <option value="">Selecione o Unidade</option>
              {unidades?.map((item) => (
                <option
                  key={item.id}
                  value={item.id}
                  selected={item.id === atendimentoUnidadeId}
                >
                  {item.nome}
                </option>
              ))}
            </select>
          </div>

          <div className="col-xl-2 col-md-6 mb-4">
            <label htmlFor="datadoagendamento">Data</label>
            <input
              type="date"
              name="datadoagendamento"
              id="datadoagendamento"
              className="form-control"
              value={datadoagendamento}
              onChange={(text) => setDataDoAgendamento(text.target.value)}
            />
          </div>
          <div className="col-xl-2 col-md-6 mb-4">
            <label htmlFor="datadoagendamentohora">Hora</label>
            <input
              type="time"
              name="datadoagendamentohora"
              id="datadoagendamentohora"
              className="form-control"
              value={datadoagendamentohora}
              onChange={(text) => setDataDoAgendamentoHora(text.target.value)}
            />
          </div>
          <div className="col-xl-2 col-md-6 mb-4">
            <label htmlFor="tipoatendimento">
              Tipo de Atendimento
            </label>
            <select
              className="custom-select my-1 mr-sm-2"
              name="tipoatendimento"
              id="tipoatendimento"
              value={tipoAtendimento}
              onChange={(text) => setTipoAtendimento(text.target.value)}
            >
              {tiposAtendimento?.map((ta) => (
                <option
                  key={ta.id}
                  value={ta.id}
                  selected={ta.id === tipoAtendimento}
                >
                  {ta.nome}
                </option>
              ))}
            </select>
          </div>
          <div className="col-xl-6 col-md-6 mb-4">
          <label htmlFor="espaco">&nbsp;</label>
            <button type="button" onClick={saveAtendimento} className="btn btn-success btn-block">Salvar</button>
          </div>
        </div>
      </Form>
      <h4>
        Agenda para o dia:&nbsp;
        {formatedDataAgendamento}
      </h4>
      <TabelaAtendimentosPresenciais atendimentosPresenciais={atendimentosPresenciais} />
      <ChangeAlert
        dirtyCalledTimes={dirtyCalledTimes}
        showChangeAlert={showChangeAlert}
        setShowChangeAlert={setShowChangeAlert}
      />
    </>
  );
}

EditPessoaAgendamento.propTypes = {
  pessoaId: PropTypes.string.isRequired,
  unidadeId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  unidades: PropTypes.array.isRequired,
};
