import React, { useEffect, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';

import api from '../../../config/configApi';

export function ViewProfile() {
  const { state } = useLocation();

  const [data, setData] = useState('');
  const [endImg, setEndImg] = useState('');
  const [urlfoto, setUrlFoto] = useState('');

  const [status, setStatus] = useState({
    type: state ? state.type : '',
    mensagem: state ? state.mensagem : '',
  });

  useEffect(() => {
    const getUser = async () => {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      await api.get('/profile/view-profile', headers)
        .then((response) => {
          if (response.data.user) {
            setEndImg(response.data.endImage);
            setUrlFoto(response.data.urlfoto);
            setData(response.data.user);
          } else {
            setStatus({
              type: 'redError',
              mensagem: 'Erro: Perfil não encontrado!',
            });
          }
        }).catch((err) => {
          if (err.response) {
            setStatus({
              type: 'redError',
              mensagem: err.response.data.mensagem,
            });
          } else {
            setStatus({
              type: 'redError',
              mensagem: 'Erro: Tente mais tarde!',
            });
          }
        });
    };

    getUser();
  }, []);

  return (

    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">

                <div className="card-header py-3">
                  <div className="row">

                      <div className="col-xl-6 col-md-6 mb-4">
                          <h6 className="m-0 font-weight-bold text-primary">Perfil</h6>
                        </div>

                      <div className="col-xl-2 col-md-6 mb-4">
                          <Link to="/edit-profile"><button type="button" className="btn btn-warning btn-block">Editar</button></Link>
                          {' '}
                        </div>

                      <div className="col-xl-2 col-md-6 mb-4">
                          <Link to="/edit-profile-password"><button type="button" className="btn btn-warning btn-block">Editar Senha</button></Link>
                          {' '}
                        </div>
                      <div className="col-xl-2 col-md-6 mb-4">
                          <Link to="/edit-profile-image"><button type="button" className="btn btn-warning btn-block">Editar Imagem</button></Link>
                          {' '}
                        </div>

                    </div>
                </div>

                <div className="card-body">
                  <div className="row">

                      <div className="alert-content-adm">
                          {status.type === 'redError'
                              ? (
                                  <Redirect to={{
                                      pathname: '/login',
                                      state: {
                                        type: 'error',
                                        mensagem: status.mensagem,
                                      }
                                    }}
                                    />
                              ) : ''}
                          {status.type === 'success' ? <p className="alert-success">{status.mensagem}</p> : ''}
                        </div>

                      <div className="content-adm">
                          <div className="view-det-adm">
                              <span className="view-adm-title">Imagem: </span>
                              <span className="view-adm-info"><img src={urlfoto} alt="Imagem do Usuário" /></span>
                            </div>

                          <div className="view-det-adm">
                              <span className="view-adm-title">ID: </span>
                              <span className="view-adm-info">{data.id}</span>
                            </div>

                          <div className="view-det-adm">
                              <span className="view-adm-title">Nome: </span>
                              <span className="view-adm-info">{data.name}</span>
                            </div>

                          <div className="view-det-adm">
                              <span className="view-adm-title">E-mail: </span>
                              <span className="view-adm-info">{data.email}</span>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="card-footer py-3" />

              </div>

            </div>
          </div>
        </div>
      </div>
      <LogOutModal />
    </>

  );
}
