import React, { useState } from 'react';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, Nav, Modal } from 'react-bootstrap';
import personFieldsTranslate from '../../../utils/enums/personFieldsTranslate';

export default function MudancasModal(
  // eslint-disable-next-line react/prop-types
  { etapa },
) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('formattedData');
  const perfilMaster = '99';

  const handleFechar = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      { perfilMaster === localStorage.getItem('perfilId') && (
        <button type="button" className="btn btn-primary  btn-sm" onClick={handleOpenModal}>
          <FontAwesomeIcon icon={faEye} />
          {' '}
          Visualizar
        </button>
      )}
      <Modal show={isOpen} onHide={handleFechar} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Mudanças</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container activeKey={activeTab}>
            <Nav variant="tabs" className="mb-3">
              <Nav.Item>
                <Nav.Link eventKey="formattedData">Dados Formatados</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="formattedData">
                <table className="table table-lg">
                  <thead>
                    <tr>
                      <th>Campo</th>
                      <th>Valor Anterior</th>
                      <th>Valor Atual</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(etapa.changedFields).map((key) => (
                        <tr>
                          <td>{personFieldsTranslate[key] || key}</td>
                          <td>{etapa.changedFields?.[key]?.original }</td>
                          <td>{etapa.changedFields?.[key]?.updated }</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                {/* Conteúdo da aba "Dados Formatados" */}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
}