import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, Form, Alert,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import api from '../../config/configApi';
import { brazillianDate, brazillianTime } from '../../utils/date';
import EmptyState from '../Shared/EmptyState';

export default function RemarcarModal(
  {
    id, unidadeId, unidadeNome, oldDataAgendamento, oldDataAgendamentoHora, unidades, tiposAtendimento,
  },
) {
  const formatDate = (date) => {
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  };
  const [reagendamento, setReagendamento] = useState({
    unidadeId,
    unidadeNome,
  });

  const [alertError, setAlertError] = useState({ isVisible: false });
  const [alertSuccess, setAlertSuccess] = useState({ isVisible: false });

  const [isRemarcarEncerrado, setIsRemarcarEncerrado] = useState(false);
  const [tipoAtendimentoId, setTipoAtendimentoId] = useState(1);
  const [scheduledAppointments, setScheduledAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [datadoagendamento, setDataDoAgendamento] = useState(
    () => {
      const splitedDate = brazillianDate(new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000)).toISOString()).split('/');
      return `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`;
    },
  );

  const [datadoagendamentohora, setDataDoAgendamentoHora] = useState(
    brazillianTime(new Date().toISOString()).split('/'),
  );

  const [formatedDataAgendamento, setFormatedDataAgendamento] = useState(
    () => formatDate(datadoagendamento),
  );

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    const defaultTipoAtendimentoId = 7;
    setTipoAtendimentoId(defaultTipoAtendimentoId);
    setIsOpen(true);
  };

  const handleRemarcar = async () => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    try {
      await api.patch(`/atendimentos-presenciais/${id}/remarcar`, {
        unidadeId: reagendamento.unidadeId,
        agendadopara: new Date(`${datadoagendamento} ${datadoagendamentohora}`),
        tipoatendimentoId: tipoAtendimentoId ? Number(tipoAtendimentoId) : 1,
      }, headers);
      setIsRemarcarEncerrado(true);
      setAlertSuccess({ isVisible: true, message: 'Reagentamento realizado com sucesso!' });
    } catch (error) {
      setAlertError({ isVisible: true, message: error.response.data.mensagem || 'Não foi possível reagendar este atendimento' });
    }
  };

  useEffect(() => {
    const storedTipoAtendimento = localStorage.getItem('tipoAtendimento');
    if (storedTipoAtendimento) {
      setTipoAtendimentoId(storedTipoAtendimento);
    }
  }, []);

  const fetchScheduledAppointments = async () => {
    setLoading(true);
    try {
      const params = {
        unidadeId: reagendamento.unidadeId,
        agendadoParaDe: formatedDataAgendamento,
        agendadoParaAte: formatedDataAgendamento,
      };
      const response = await api.get('/atendimentos-presenciais', { params });
      setScheduledAppointments(response.data.atendimentosPresenciais);
    } catch (error) {
      console.error('Erro ao buscar agendamentos:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && reagendamento.unidadeId && formatedDataAgendamento) {
      fetchScheduledAppointments();
    }
  }, [reagendamento.unidadeId, datadoagendamento, isOpen]);

  return (
    <>
      <Button variant="secondary m-1" type="button" onClick={handleOpenModal}>
        <span>
          <FontAwesomeIcon icon={faCalendarDays} />
        </span>
        &nbsp;Remarcar
      </Button>
      <Modal show={isOpen} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Remarcar Atendimento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="form-remarcar-agendamento">
            { alertSuccess.isVisible
              && (
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <Alert variant="success" onClose={() => setAlertSuccess({ isVisible: false })} dismissible>
                        <p className="text-center mt-3">
                          {alertSuccess.message}
                        </p>
                      </Alert>
                    </div>
                  </div>
                </div>
              )}
            { alertError.isVisible
              && (
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <Alert variant="danger" onClose={() => setAlertError({ isVisible: false })} dismissible>
                        <p className="text-center mt-3">
                          {alertError.message}
                        </p>
                      </Alert>
                    </div>
                  </div>
                </div>
              )}
            <fieldset disabled={isRemarcarEncerrado}>
              <div className="form-group row align-items-end">
                <div className="form-group col-md-4">
                  <label htmlFor="unidade">
                    Unidade
                  </label>
                  <Form.Select
                    className="custom-select my-1 mr-sm-2"
                    name="unidade"
                    id="unidade"
                    placeholder="unidade"
                    required="required"
                    value={reagendamento.unidadeId}
                    onChange={(text) => {
                      setReagendamento((prev) => ({
                        ...prev,
                        unidadeId: text.target.value,
                        unidadeNome: unidades.find(
                          (unidade) => unidade.id === +text.target.value,
                        )?.nome,
                      }));
                    }}
                  >
                    <option value="">Selecione a Unidade</option>
                    {unidades?.map((item) => (
                      <option
                        key={item.nome}
                        value={item.id.toString()}
                      >
                        {item.nome}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="datadoagendamento">Data</label>
                  <input
                    type="date"
                    name="datadoagendamento"
                    id="datadoagendamento"
                    className="form-control"
                    value={datadoagendamento}
                    onChange={(text) => {
                      setDataDoAgendamento(text.target.value);
                      setFormatedDataAgendamento(formatDate(text.target.value));
                    }}
                  />
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="datadoagendamentohora">Hora</label>
                  <input
                    type="time"
                    name="datadoagendamentohora"
                    id="datadoagendamentohora"
                    className="form-control"
                    value={datadoagendamentohora}
                    onChange={(text) => { setDataDoAgendamentoHora(text.target.value); }}
                  />
                </div>

                <div className="form-group col-md-2">
                  <label htmlFor="tipoatendimento">
                    Tipo de Atendimento
                  </label>
                  <select
                    className="custom-select my-1 mr-sm-2"
                    name="tipoatendimento"
                    id="tipoatendimento"
                    value={tipoAtendimentoId}
                    onChange={(event) => {
                      const selectedTipoAtendimento = event.target.value;
                      setTipoAtendimentoId(selectedTipoAtendimento);
                    }}
                  >
                    <option value="">Selecione o Tipo de Atendimento</option>
                    {tiposAtendimento?.map((ta) => (
                      <option
                        key={ta.id}
                        value={ta.id}
                      >
                        {ta.nome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </fieldset>
          </Form>
          { reagendamento.unidadeId
            ? (
              <>
                Remarcando agendamento
                <br />
                <p>
                  <b>
                    {'De: '}
                  </b>
                  <br />
                  <u>
                    {oldDataAgendamento}
                  </u>
                  {' às '}
                  <u>
                    {oldDataAgendamentoHora}
                  </u>
                  { ' na unidade ' }
                  <u>
                    {unidadeNome}
                  </u>
                  <br />
                  <b>
                    {'Para: '}
                  </b>
                  <br />
                  <u>
                    {formatedDataAgendamento}
                  </u>
                  {' às '}
                  <u>
                    {datadoagendamentohora}
                  </u>
                  { ' na unidade ' }
                  <u>
                    {reagendamento.unidadeNome}
                  </u>
                </p>
              </>
            ) : ''}
          <h5 className="">
            Agendamentos marcados para o dia
            &nbsp;
            {formatedDataAgendamento}
            &nbsp;
            na Unidade
            &nbsp;
            {reagendamento.unidadeNome}
            :
          </h5>
          {loading ? (
            <p>Carregando agendamentos...</p>
          ) : (
            <div className="table-responsive">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Data do Agendamento</th>
                    <th>Hora do Agendamento</th>
                    <th>Benefício</th>
                    <th>Agendado Por</th>
                    <th>Tipo de Atendimento</th>
                    <th>Unidades</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    scheduledAppointments.map((appointment) => (
                      <tr key={appointment.id}>
                        <td className="align-middle">
                          <a href={`/edit-pessoa/${appointment.pessoa.id}`} target="_blank" rel="noreferrer">
                            {appointment.pessoa.nome}
                          </a>
                        </td>
                        <td className="align-middle">{brazillianDate(appointment.agendadopara)}</td>
                        <td className="align-middle">{brazillianTime(appointment.agendadopara)}</td>
                        <td className="align-middle">{appointment.pessoa?.beneficio}</td>
                        <td className="align-middle">{appointment.usuarioqueagendou?.nome}</td>
                        <td className="align-middle">{appointment.tipoatendimento?.nome}</td>
                        <td className="align-middle">{appointment.unidade?.nome}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          )}
          <EmptyState isActive={!scheduledAppointments.length} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleRemarcar}>
            Remarcar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

RemarcarModal.propTypes = {
  id: PropTypes.number.isRequired,
  unidadeId: PropTypes.number.isRequired,
  unidadeNome: PropTypes.string.isRequired,
  oldDataAgendamento: PropTypes.string.isRequired,
  oldDataAgendamentoHora: PropTypes.string.isRequired,
  unidades: PropTypes.objectOf({
    id: PropTypes.number.isRequired,
    nome: PropTypes.string.isRequired,
  }).isRequired,
  tiposAtendimento: PropTypes.object.isRequired,
};
