import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';

import 'moment/locale/pt-br';
import api from '../../../config/configApi';
import { servDeleteUser } from '../../../services/servDeleteUser';

export function AgendamentoRealizado(props) {
  const moment = require('moment');

  const [id] = useState(props.match.params.id);
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [unidade, setUnidade] = useState('');
  const [atendente, setAtendente] = useState('');
  const [origemdasligacoes, setOrigemDasLigacoes] = useState('');
  const [datadasligacoes, setDataDasLigacoes] = useState('');
  const [cpf, setCpf] = useState('');
  const [telefone, setTelefone] = useState('');
  const [telefoneobs, setTelefoneObs] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [telefone2obs, setTelefone2Obs] = useState('');
  const [telefone3, setTelefone3] = useState('');
  const [telefone3obs, setTelefone3Obs] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [cep, setCep] = useState('');
  const [datanascimento, setDataNascimento] = useState('');
  const [datadoagendamento, setDataDoAgendamento] = useState('');
  const [datadoagendamentohora, setDataDoAgendamentoHora] = useState('');
  const [datadoreagendamento, setDataDoReagendamento] = useState('');
  const [datadoreagendamentohora, setDataDoReagendamentoHora] = useState('');
  const [datadoreagendamento2, setDataDoReagendamento2] = useState('');
  const [datadoreagendamento2hora, setDataDoReagendamento2Hora] = useState('');
  const [atendidopor, setAtendidoPor] = useState('');
  const [beneficio, setBeneficio] = useState('');
  const [indicacao, setIndicacao] = useState('');
  const [contrato, setContrato] = useState('');
  const [informacaodoatendimento, setInformacaoDoAtendimento] = useState('');
  const [reagendamento, setReagendamento] = useState('');
  const [statusexclusivo, setStatusExclusivo] = useState('');
  const [lancadopor, setLancadoPor] = useState('');
  const [alteradopor, setAlteradoPor] = useState('');
  const [pastadedocumentos, setPastaDeDocumentos] = useState('');

  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });

  const editUser = async (e) => {
    e.preventDefault();

    // if (!(await validate())) return;

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    await api.put('/pessoa/pessoa', {
      id, nome, email, unidade, atendente, origemdasligacoes, datadasligacoes, cpf, telefone, telefoneobs, telefone2, telefone2obs, telefone3, telefone3obs, logradouro, numero, complemento, bairro, cidade, cep, datanascimento, datadoagendamento, datadoagendamentohora, datadoreagendamento, datadoreagendamentohora, datadoreagendamento2, datadoreagendamento2hora, atendidopor, beneficio, indicacao, contrato, informacaodoatendimento, reagendamento, statusexclusivo, lancadopor, alteradopor, pastadedocumentos,
    }, headers)
      .then((response) => {
        setStatus({
          type: 'redSuccess',
          mensagem: response.data.mensagem,
        });
        alert('Sucesso na Edição!');
        console.log('atualizou');
      }).catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
          console.log('nao atualizou');
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',

          });
          console.log('Erro: Tente mais tarde!');
        }
      });
  };

  useEffect(() => {
    const getUser = async () => {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      await api.get(`/pessoa/pessoa/${id}`, headers)
        .then((response) => {
          if (response.data.user) {
            setNome(response.data.user.nome);
            setEmail(response.data.user.email);
            setUnidade(response.data.user.unidade);
            setAtendente(response.data.user.atendente);
            setOrigemDasLigacoes(response.data.user.origemdasligacoes);
            setDataDasLigacoes(response.data.user.datadasligacoes);
            setCpf(response.data.user.cpf);
            setTelefone(response.data.user.telefone);
            setTelefoneObs(response.data.user.telefoneobs);
            setTelefone2(response.data.user.telefone2);
            setTelefone2Obs(response.data.user.telefone2obs);
            setTelefone3(response.data.user.telefone3);
            setTelefone3Obs(response.data.user.telefone3obs);
            setLogradouro(response.data.user.logradouro);
            setNumero(response.data.user.numero);
            setComplemento(response.data.user.complemento);
            setBairro(response.data.user.bairro);
            setCidade(response.data.user.cidade);
            setCep(response.data.user.cep);
            setDataNascimento(response.data.user.datanascimento);
            setDataDoAgendamento(response.data.user.datadoagendamento);
            setDataDoAgendamentoHora(response.data.user.datadoagendamentohora);
            setDataDoReagendamento(response.data.user.datadoreagendamento);
            setDataDoReagendamentoHora(response.data.user.datadoreagendamentohora);
            setDataDoReagendamento2(response.data.user.datadoreagendamento2);
            setDataDoReagendamento2Hora(response.data.user.datadoreagendamento2hora);
            setAtendidoPor(response.data.user.atendidopor);
            setBeneficio(response.data.user.beneficio);
            setIndicacao(response.data.user.indicacao);
            setContrato(response.data.user.contrato);
            setInformacaoDoAtendimento(response.data.user.informacaodoatendimento);
            setReagendamento(response.data.user.reagendamento);
            setStatusExclusivo(response.data.user.statusexclusivo);
            setLancadoPor(response.data.user.lancadopor);
            setAlteradoPor(response.data.user.alteradopor);
            setPastaDeDocumentos(response.data.user.pastadedocumentos);
          } else {
            setStatus({
              type: 'redWarning',
              mensagem: 'Erro: Usuário não encontrado!',
            });
          }
        }).catch((err) => {
          if (err.response) {
            setStatus({
              type: 'redWarning',
              mensagem: err.response.data.mensagem,
            });
          } else {
            setStatus({
              type: 'redWarning',
              mensagem: 'Erro: Tente mais tarde!',
            });
          }
        });
    };

    getUser();
  }, [id]);

  async function validate() {
    const schema = yup.object().shape({
      nome: yup.string('Erro: Necessário preencher o campo nome!')
        .required('Erro: Necessário preencher o campo nome!'),

    });

    try {
      await schema.validate({ nome });
      return true;
    } catch (err) {
      setStatus({ type: 'error', mensagem: err.errors });
      return false;
    }
  }

  const deleteUser = async (idUser) => {
    const response = await servDeleteUser(idUser);
    if (response) {
      if (response.type === 'success') {
        setStatus({
          type: 'redSuccess',
          mensagem: response.mensagem,
        });
      } else {
        setStatus({
          type: 'error',
          mensagem: response.mensagem,
        });
      }
    } else {
      setStatus({
        type: 'error',
        mensagem: 'Erro: Tente mais tarde!',
      });
    }
  };

  const [page, setPage] = useState('');
  const [lastPage, setLastPage] = useState('');
  const [unidades, setUnidades] = useState([]);
  const getUnidades = async (page) => {
    if (page === undefined) {
      page = 1;
    }
    setPage(page);

    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    await api.get(`/unidade/unidades/${page}`, headers)
      .then((response) => {
        setUnidades(response.data);
        console.log(unidade.users);
      }).catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getUnidades();
  }, []);

  return (

    <>
      <div id="wrapper">
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />

            <div className="container-fluid">

              <div className="col-xl-12 col-md-6 mb-4">
                <div className="card border-success shadow h-100 py-2">
                  <div className="card-body">
                      <div className="row no-gutters align-items-center text-center">
                          <div className="col mr-2">

                              <div className="text-xls font-weight-bold text-success text-uppercase mb-2">
                                  AGENDAMENTO REALIZADO <small>&#9989;</small>
                                </div>

                              <p>
                                  Agendado para Unidade:<strong>{unidade}</strong>
                                </p>

                              ENDEREÇO: 
                                                {(unidade === 'ALCÂNTARA')
                                                  ? (
                                                    <p className="text-uppercase">
                                                      ESTRADA RAUL VEIGA, 351 A SALA: 925 PONTO DE REFERÊNCIA: LOJAS CASAS PEDRO / EM FRENTE AO BANCO ITAÚ
                                                      (21) 3583-5882 | (21) 97448-4674
                                                    </p>
                                                  ) : ''}

                              {(unidade === 'ANGRA DOS REIS')
                                  ? (
                                      <p className="text-uppercase">
                                          RUA DOUTOR JOSÉ WATANABE, 55  SALA: 607 PONTO DE REFERENCIA: PARQUE DAS PALMEIRAS. ANTIGO PRÉDIO DA UNIMED
                                          (24) 3368-8652 | (24) 99985-8360
                                                </p>
                                  ) : ''}

                              {(unidade === 'CENTRO')
                                  ? (
                                      <p className="text-uppercase">
                                          AV. RIO BRANCO, 156 SALA 3236, CENTRO PONTO DE REFERÊNCIA: LARGO DA CARIOCA. EDIFICIL CENTRAL.
                                          (21) 2146-9011 | (21) 99129-0302
                                                </p>
                                  ) : ''}

                              {(unidade === 'JUAZEIRO (BA)')
                                  ? (
                                      <p className="text-uppercase">
                                          RUA CANADÁ,N°23-A,23-B, SANTA MARIA GORETTI-JUAZEIRO/BA. PONTO DE REFERÊNCIA: Em frente ao Inss/Vizinho ao antigo canteiro de obras/Na Rua Gata Gabola.
                                          (74) 3061-9077 | (74) 98134-4911
                                                </p>
                                  ) : ''}

                              {(unidade === 'NILÓPOLIS')
                                  ? (
                                      <p className="text-uppercase">
                                          RUA PEDRO ÁLVARES CABRAL, 256, SALA 124,CENTRO-NILÓPOLIS RJ - PONTO DE REFÊNCIA: EDIFÍCIO SAMIRA, PRÓXIMO A PREFEITURA
                                          (21) 2792-0487 | (21) 97879-1285
                                                </p>
                                  ) : ''}

                              {(unidade === 'PETROLINA (PE)')
                                  ? (
                                      <p className="text-uppercase">
                                          RUA DOUTOR JÚLIO DE MELO,N°436-A,CENTRO-PETROLINA/PE. PONTO DE REFERÊNCIA: Praça das Algarobas/Próximo a Jacauna Móveis/Vizinho ao Hotel Real.
                                          (87)2101-7737 | (87)99160-0047
                                                </p>
                                  ) : ''}

                              {(unidade === 'TAQUARA')
                                  ? (
                                      <p className="text-uppercase">
                                          AV. NELSON CARDOSO, 309 SALA 626, TANQUE PRÓXIMO A ESTAÇÃO DE  BRT DO TANQUE
                                          (21) 3547-2980 | (21) 97621-6658
                                                </p>
                                  ) : ''}

                              DATA: <strong>
                                  {' '}
                                  {moment(datadoagendamento).format('DD/MM/YYYY')}
                                  {' '}
                                  Hora: 
{' '}
                                  {datadoagendamentohora}
                                      </strong>
                              <div className="my-2" />

                              <div className="row">
                                  <div className="col-xl-4 col-md-6 mb-4">

                                      <Link to="/pessoas" style={{ textDecoration: 'none' }}>
                                          <button type="button" className="btn btn-info btn-block">Novo Atendimento</button>
                                        </Link>

                                    </div>

                                  <div className="col-xl-4 col-md-6 mb-4">

                                      <Link to={`/edit-pessoa/${id}`} style={{ textDecoration: 'none' }}>
                                          <button type="button" className="text text-black-50 btn btn-warning btn-block">Editar Agendamento</button>
                                        </Link>

                                    </div>

                                  <div className="col-xl-4 col-md-6 mb-4">

                                      <a href={`https://web.whatsapp.com/send/?phone=55${telefone}&text=Agendamento&type=phone_number&app_absent=0`} target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer"><button type="button" className="btn btn-success btn-block" style={{ textDecoration: 'none' }}>Enviar WhatsApp</button></a>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
