export default {
  unidade: 'Unidade',
  origemdasligacoes: 'Origem das ligações',
  atendente: 'Atendente',
  datadasligacoes: 'Data das ligações',
  cpf: 'CPF',
  nome: 'Nome',
  telefoneprincipal: 'Telefone',
  obstelefoneprincipal: 'Obs. telefone',
  telefonesegundo: '2º Telefone',
  obstelefonesegundo: 'Obs. 2º Telefone',
  telefoneoutros: '3º Telefone',
  obstelefoneoutros: 'Obs. 3º Telefone',
  cadUnico: 'Cadastro Único',
  logradouro: 'Logradouro',
  numero: 'Número',
  complemento: 'Complemento',
  bairro: 'Bairro',
  cidade: 'Cidade',
  cep: 'CEP',
  datanascimento: 'Data de nascimento',
  datadoagendamento: 'Data do agendamento',
  datadoagendamentohora: 'Data do agendamento e hora',
  datadoreagendamento: 'Data do reagendamento',
  datadoreagendamentohora: 'Data do reagendamento e hora',
  datadoreagendamento2: 'Data do reagendamento 2',
  datadoreagendamento2hora: 'Data do reagendamento 2 e hora',
  atendidopor: 'Atendido por',
  beneficio: 'Benefício',
  indicacao: 'Indicação',
  observacaoindicacao: 'Observação da indicação',
  contrato: 'Contrato',
  informacaodoatendimento: 'Informação do atendimento',
  reagendamento: 'Reagendamento',
  statusexclusivo: 'Status exclusivo',
  lancadopor: 'Lançado por',
  alteradopor: 'Alterado por',
  email: 'E-mail',
  pastadedocumentos: 'Pasta de documentos',
  senhaINSS: 'Senha do INSS',
  rg: 'RG',
  etinia: 'Etnia',
  estadoCivil: 'Estado civil',
  isIdoso: 'Idoso',
  isDeficiente: 'Deficiente',
  isEstrangeiro: 'Estrangeiro',
  sexo: 'Sexo',
  observacao: 'Observação',
  estado: 'Estado',
  assinouContrato: 'Assinou o contrato',
  observacaoContrato: 'Observação do contrato',
  escolaridade: 'Escolaridade',
  situacaoStatus: 'Situação',
  pagamentoStatus: 'Status do pagamento',
  updatedAt: 'Atualizado em',
  createdAt: 'Criado em',
  creatorId: 'Identificação do criador',
};
