import React, { useContext, useState } from 'react';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar';
import { LogOutModal } from '../../components/LogOutModal';
import { Context } from '../../Context/AuthContext';

export function Dashboard() {
  const { handleLogout } = useContext(Context);

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container">

              {/*  <!-- Page Heading --> */}
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">MARCELO MANHÃES</h1>

              </div>

              <div className="form-group row">

                <div className="col-lg-3">

                  <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Menu do Sistema</h6>
                  </div>
                <div className="card-body">

                    <a href="/pessoas" className="btn btn-warning btn-icon-split btn-sm">
                                <span className="icon text-white-50">
                                    <i className="fas fa-file" />
                                  </span>
                                <span className="text text-black-50">Localizar Cliente</span>
                              </a>

                    <div className="my-2" />
                    <a href="/ajuda" className="btn btn-primary btn-icon-split btn-sm">
                                <span className="icon text-white-50">
                                    <i className="fas fa-info-circle" />
                                  </span>
                                <span className="text">Informações&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              </a>

                    <div className="my-2" />
                    <a href="/chamados" target="_blank" className="btn btn-success btn-icon-split btn-sm">
                                <span className="icon text-white-50">
                                    <i className="fas fa-phone" />
                                  </span>
                                <span className="text">Chamados TI&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              </a>

                    <div className="my-2" />
                    <a href="/users" className="btn btn-info btn-icon-split btn-sm">
                                <span className="icon text-white-50">
                                    <i className="fas fa-user" />
                                  </span>
                                <span className="text">Usuários&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              </a>
                    <div className="my-2" />
                    <a href="/" onClick={handleLogout} className="btn btn-danger btn-icon-split btn-sm">
                                <span className="icon text-white-50">
                                    <i className="fas fa-sign-out-alt" />
                                  </span>
                                <span className="text">Sair do Sistema</span>

                              </a>
                  </div>
              </div>

                </div>

                <div className="col-lg-5">

                  <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Unidades</h6>
                  </div>
                <div className="card-body">
                    <small>
                                <p>

                                    <a href="https://wa.me/5524999858360" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">ANGRA DOS REIS</a>
                                  </p>
                                <p>
                                    ENDEREÇO:	RUA DOUTOR JOSÉ WATANABE, 55				SALA: 607
                                    TEL FIXO:	(24) 3368-8652
                                    TEL CELULAR:	(24) 99985-8360
                                    PONTO DE REFERENCIA: PARQUE DAS PALMEIRAS. ANTIGO PRÉDIO DA UNIMED
                                    EMAIL: mmanhaesangra@outlook.com
                                        </p>
                                <p>
                                    <a href="https://wa.me/5521974484674" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">ALCÂNTARA</a>
                                  </p>
                                <p>
                                    ENDEREÇO:	ESTRADA RAUL VEIGA, 351 A.  				SALA: 925
                                    TEL FIXO:	(21) 3583-5882
                                    TEL CELULAR:	(21) 97448-4674
                                    EMAIL:	mmsaogoncalo@gmail.com
                                    PONTO DE REFERENCIA:		LOJAS CASAS PEDRO
                                    EM FRENTE AO BANCO ITAÚ
                                        </p>
                                <p>
                                    <a href="https://wa.me/5521991290302" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">CENTRO</a>
                                  </p>
                                <p>
                                    ENDEREÇO:	AV. RIO BRANCO, 156
                                    TEL FIXO:	(21) 2146-9011
                                    TEL CELULAR:	(21) 99129-0302				LOAS E APOSENTADORIA
                                    EMAIL:	marcelomanhaesassessoria@gmail.com				SEG A SEXT SALA: 3236
                                    PONTO DE REFERÊNCIA: LARGO DA CARIOCA. EDIFICIL CENTRAL.
                                    AO LADO DA ESTAÇÃO DE METRÔ DA CARIOCA.
                                        </p>
                                <p>
                                    <a href="https://wa.me/5574981344911" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">JUAZEIRO (BAHIA)</a>
                                  </p>
                                <p>
                                    ENDEREÇO:	RUA CANADÁ,N°23-A,23-B, SANTA MARIA GORETTI-JUAZEIRO/BA.
                                    TEL FIXO:	(74) 3061-9077
                                    TEL CELULAR:	(74) 98134-4911
                                    EMAIL:	mmanhaesjuazeiro@gmail.com
                                    PONTO DE REFERENCIA: 		Em frente ao Inss/Vizinho ao antigo canteiro de obras/Na Rua Gata Gabola.
                                        </p>
                                <p>
                                    <a href="https://wa.me/5587991600047" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">PETROLINA (PERNAMBUCO)</a>
                                  </p>
                                <p>
                                    ENDEREÇO:	RUA DOUTOR JÚLIO DE MELO,N°436-A,CENTRO-PETROLINA/PE.
                                    TEL FIXO:	(87)2101-7737
                                    TEL CELULAR:	(87)99160-0047
                                    EMAIL:  	mmanhaespetrolina@gmail.com
                                    PONTO DE REFERENCIA:		Praça das Algarobas/Próximo a Jacauna Móveis/Vizinho ao Hotel Real.
                                        </p>
                                <p>
                                    <a href="https://wa.me/5521978791285" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">NILÓPOLIS</a>
                                  </p>
                                <p>
                                    ENDEREÇO:	RUA PEDRO ÁLVARES CABRAL,256, 1°ANDAR,CENTRO-NILÓPOLIS RJ.
                                    TEL FIXO:	(21) 2792-0487				SALA: 124 LOAS
                                    TEL CELULAR:	(21) 97879-1285				SALA: 111 APOSENT.
                                    EMAIL:	mmanhaesnilopolis@gmail.com
                                    PONTO DE REFERENCIA:		EDIFÍCIO SAMIRA, PRÓXIMO A PREFEITURA.
                                        </p>
                                <p>
                                    <a href="https://wa.me/5521976216658" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">TAQUARA</a>
                                  </p>
                                <p>
                                    ENDEREÇO:	AV. NELSON CARDOSO, 309 TANQUE
                                    TEL FIXO:	(21) 3547-2980				SALA: 626 LOAS
                                    TEL CELULAR:	(21) 97621-6658				SALA: 627 APOSENT.
                                    EMAIL:	taquara@marcelomanhaesassessoria.com.br
                                    PONTO DE REFERENCIA:		PRÓXIMO A ESTAÇÃO DE  BRT DO TANQUE
                                    PRÉDIO AO LADO DO HOSPITAL DOS BRINQUEDOS		
                                        </p>

                                <p>
                                    <a href="https://wa.me/5531983670661" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">BELO HORIZONTE</a>
                                  </p>
                                <p>
                                    Endereço: Rua Espírito Santo, 989- Centro
                                    Telefone: (31) 3646-2488
                                    Telefone celular: (31) 98367-0661 / (31) 98370-7141
                                    Email: mmbelohorizonte@outlook.com
                                    Ponto de referência: Em frente à loja da Claro, entre Rua dos Goytacazes e Av Augusto de Lima.
                                    A 2 quarteirões acima da igreja São José e 2 quarteirões do Shopping Cidade. 	
                                        </p>

                              </small>

                  </div>
              </div>

                </div>

                <div className="col-lg-4">

                  <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Links Úteis</h6>
                  </div>
                <div className="card-body">
                    <small>
                                <p><a href="https://marcelomanhaesassessoria.com.br" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">Site Marcelo Manhães</a></p>
                                <p><a href="https://meu.inss.gov.br" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">Meu INSS</a></p>
                                <p><a href="https://servicos.receita.fazenda.gov.br/Servicos/CPF/ConsultaSituacao/ConsultaPublica.asp" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">Consultar CPF</a></p>
                                <p><a href="https://falecidosnobrasil.org.br" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">Falecidos no Brasil</a></p>
                                <p><a href="https://www.nibo.com.br" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">Nibo</a></p>

                                <p><a href="https://www.gov.br/inss/pt-br/centrais-de-conteudo/formularios" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">Formulários INSS</a></p>

                              </small>

                  </div>
              </div>

                  <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Ramais</h6>
                  </div>
                <div className="card-body">
                    <small>
                                <ul>
                                    <li>Aline Sala: 3210 Ramal 5023</li>
                                    <li>Amanda /Exigência Sala: 3214 Ramal 5014</li>
                                    <li>Ana Maria Sala: 3234 Ramal 5024</li>
                                    <li>Andressa /Atendimento Sala: 3237 Ramal 5021</li>
                                    <li>Ariane / Jacqueline Sala: 0 Ramal 5100</li>
                                    <li>Camila Sala: 3200 Ramal 5010</li>
                                    <li>Cristiane Sala: 3200 Ramal 5005</li>
                                    <li>Daniele | Financeiro Sala: 3234 Ramal 5018</li>
                                    <li>Daniele | Recebimento Sala: 3234 Ramal 5019</li>
                                    <li>Dayane /Central Sala: 3232 Ramal 5015</li>
                                    <li>Éshyla Sala: 0 Ramal 5201</li>
                                    <li>Fernanda E Shayene /Dp Sala: 3210 Ramal 5012</li>
                                    <li>Juliana - Sup Sala: 621 Ramal 5025</li>
                                    <li>Luana | Recepção Sala: 3237 Ramal 5020</li>
                                    <li>Marcelo Sala: 3232 Ramal 5017</li>
                                    <li>Margarida Sala: 3210 Ramal 5011</li>
                                    <li>Mel E Victoria /Central Sala: 3232 Ramal 5016</li>
                                    <li>Michelli Sala: 3132|0800 Ramal 5001</li>
                                    <li>Nathalia Sala: 3210 Ramal 5013</li>
                                    <li>Recepção Sala: 0 Ramal 5200</li>
                                    <li>Recepção/Isabeli/Mirian Sala: 0 Ramal 6003</li>
                                    <li>Ruymar Sala: 3200 Ramal 5099</li>
                                    <li>Ti Sala: 3202 Ramal 5095</li>
                                    <li>Yasmin / Damião Sala: 0 Ramal 5101</li>
                                  </ul>

                              </small>

                  </div>
              </div>

                </div>

              </div>
              {/*
                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                            </ol>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img class="d-block w-100" src="/img/sitemarcelomanhes3.png" alt="Primeiro slide" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="/img/sitemarcelomanhes2.png" alt="Secundo slide" />
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="/img/sitemarcelomanhes1.png" alt="Terceiro slide" />
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Anterior</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Próximo</span>
                            </a>
                        </div> */}

            </div>
          </div>
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
