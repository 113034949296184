import axios from 'axios';
import specialCharacters from './generic';

export default async function getCep(code) {
  try {
    const handledCode = code.replace(specialCharacters, '');
    if (handledCode.length !== 8) {
      return null;
    }

    const { data } = await axios.get(`https://viacep.com.br/ws/${handledCode}/json/`);
    return data;
  } catch (error) {
    return null;
  }
}
