const brTimezone = 'America/Sao_Paulo';

function brazillianDate(date) {
  const utcDate = new Date(date);
  const options = {
    day: '2-digit', month: '2-digit', year: 'numeric', timeZone: brTimezone,
  };
  return utcDate.toLocaleDateString('pt-BR', options);
}

function brazillianTime(date) {
  const utcDate = new Date(date);
  const options = {
    hour: '2-digit', minute: '2-digit', timeZone: brTimezone,
  };
  return utcDate.toLocaleTimeString('pt-BR', options);
}

function brazillianDateTime(dateTime) {
  const utcDate = new Date(dateTime);
  const options = {
    day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: brTimezone,
  };
  return utcDate.toLocaleTimeString('pt-BR', options);
}

export { brazillianDate, brazillianTime, brazillianDateTime };
