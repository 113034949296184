import { PropTypes } from 'prop-types';
import React from 'react';

export default function EmptyState({ isActive }) {
  return (
    isActive
      ? (
        <div className="empty-state m-5">
          <div className="empty-state-icon text-center">
            <i className="fas fa-2xl fa-exclamation-triangle" />
          </div>
          <h2 className="text-center">Sem resultados</h2>
          <h2 className="text-center">{isActive}</h2>
          <p className="text-center">Nenhum registro foi localizado.</p>
        </div>
      )
      : null
  );
}

EmptyState.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
